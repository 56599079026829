import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss']
})
export class MeetingComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  private unsubscribe$ = new Subject<void>();

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone) { }

  meetingForm: FormGroup;
  showSpinner: boolean;
  formDisabled: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;

  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    isMember: [
      { type: 'required', message: 'Please indicate if you are an NCA member or not'}
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ]
  };

  ngOnInit() {
    this.showSpinner = false;
    this.formDisabled = true;
    this.meetingForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      group: [{value: '', disabled: false}],
      groupSize: [{value: '', disabled: false}],
      dayTime: [{value: '', disabled: false}],
      setup: [{value: '', disabled: false}],
      isMember: [{value: '', disabled: false}, Validators.required],
      dayphone: [{value: '', disabled: false}],
      eveningphone: [{value: '', disabled: false}],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]]
    });
    this.api.get('/committees/28')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }
  sendForm() {
    if (this.meetingForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 28,
        formData: {
          fullName: this.meetingForm.controls.name.value,
          fullAddress: this.meetingForm.controls.address.value,
          emailAddress: this.meetingForm.controls.emailAddress.value,
          daytimePhone: this.meetingForm.controls.dayphone.value,
          eveningPhone: this.meetingForm.controls.eveningphone.value,
          ncaMember: this.meetingForm.controls.isMember.value,
          group: this.meetingForm.controls.group.value,
          groupSize: this.meetingForm.controls.groupSize.value,
          dayTime: this.meetingForm.controls.dayTime.value,
          setup: this.meetingForm.controls.setup.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to submit form.';
        this.infoModalRef.componentInstance.infoMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.meetingForm.reset({
        name: '',
        address: '',
        group: '',
        groupSize: '',
        dayTime: '',
        setup: '',
        isMember: '',
        dayphone: '',
        eveningphone: '',
        emailAddress: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Meeting Room coordinator.' +
        ' Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
