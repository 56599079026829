<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025RVParkingForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-md-12">
      <h3>RV CAMPING &amp; PARKING</h3>
      <p>We have made arrangements with the Crowne Plaza for RV parking on the hotel grounds.  There are a limited number
        of spaces available by <span class="fw-bold">PREPAID RESERVATION ONLY</span>.
        There are <span class="fw-bold">NO ELECTRICAL OR WATER HOOK-UPS</span>. There is access to a water and dumping
        station within 6 miles of the hotel. All parking spaces will be pre-assigned.  The fee is $90 per day, This fee
        is in effect from Monday April 28th to Sunday May 4th and the fee charged by the hotel, including tax.
        Information will be sent to persons making reservations.</p>
		<p class="fw-bold">Due to the size of the parking lots and other events that may be taking place at the hotel during
      the week, no RVs can be parked at any time without a reservation.  This includes those that belong to people who
      are staying in the hotel.  The hotel may waive your RV parking fee but we still need to provide you with a site in
      our RV space and need you to send in a reservation form.</p>
		<h3>RV Parking Rules</h3>
      <p>Submission of an application for RV parking constitutes an agreement by the submitter to abide by the following
        basic RV parking rules:</p>
      <ul>
        <li>Generators may NOT be operated between the hours of 9 pm and 7 am.</li>
        <li>All RVs must be self-contained, and Holding Tanks must be kept closed. No dumping of gray water or sanitary
          tanks permitted anywhere on the hotel grounds. Slow dripping of gray water is not allowed. Any violation of
          this will result in you being immediately made to leave.</li>
        <li>Pump service will be available at additional cost (info will be sent with registration packet). There is also
          a dump center which is approximately 6 miles away. There you will be able to dump your waste water and refill
          your potable water for a fee.</li>
        <li>Fire Lanes and No Parking areas will be maintained.</li>
        <li>No dogs shall be left unattended in outside your RV.</li>
        <li>Everyone is expected to keep his or her RV area clean. Garbage and “poop” must be disposed of properly.
          Washing of dogs in the RV area IS NOT allowed. Please collect loose dog hair resulting from grooming.
          After all, no one wants your dog hair in his or her parking space.</li>
        <li>RVs may be parked at the site beginning at Noon on Monday, April 28th.  RV parking on site will not be
          allowed prior to this time except by special request.  RVs must be off the site by 11 am on Sunday, May 4th.</li>
      </ul>
      <p class="text-center fst-italic">Parking at the designated RV area is at the owner’s risk. The show-giving club,
        its members, executive board, officers, agents, superintendents, and the Crowne Plaza will not be liable for any
        damages, theft, loss or injury which may be sustained while on the show grounds.</p>

      <p><em>Deadline {{ deadline }}. </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
      <h2 class="mt-3 float-none">Online RV Parking Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12">
            <form id="parkForm" [formGroup]="parkForm" #lf="ngForm">
              <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</h5>
              <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
              <p>Yes, I want to order reserved RV Parking for the 2025 National Specialty</p>
              <div class="form-row mb-3 d-none">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Select a type of parking</mat-label>
                  <mat-select formControlName="parkType" placeholder="Type of Parking" (selectionChange)="changeParkingType($event)">
                    <mat-option *ngFor="let parkType of parkTypes" [value]="parkType.id">{{ parkType.optionName }}</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.parkType">
                    <mat-error class="error-message" *ngIf="parkForm.get('parkType').hasError(validation.type) && (parkForm.get('parkType').dirty || parkForm.get('parkType').touched)">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Arrival Day/Date" formControlName="arrival">
                  <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('arrival').hasError(validation.type) && (parkForm.get('arrival').dirty || parkForm.get('arrival').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Departure Day/Date" formControlName="departure">
                  <mat-error *ngFor="let validation of accountValidationMessages.departure">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('departure').hasError(validation.type) && (parkForm.get('departure').dirty || parkForm.get('departure').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput id="numberNights" type="number" formControlName="numberNights"
                         placeholder="Number of Nights" (change)="changeNights()" min="0">
                  <mat-error *ngFor="let validation of accountValidationMessages.numberNights">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('numberNights').hasError(validation.type) && (parkForm.get('numberNights').dirty || parkForm.get('numberNights').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-third-width">
                  <input matInput formControlName="type" placeholder="Type of Vehicle:">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput formControlName="length" placeholder="Length of Vehicle:">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput formControlName="addVehicle" placeholder="Additional/tow Vehicle: (Y/N)">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-third-width">
                  <input matInput formControlName="addVehicleLength" placeholder="Length of Additional Vehicle:">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput formControlName="name" placeholder="Name of Parking Buddy">
                </mat-form-field>
                <mat-form-field class="inputField-third-width">
                  <input matInput formControlName="hotel" placeholder="Are you staying in the hotel? (Y/N)">
                </mat-form-field>
              </div>
              <div class="form-row mt-3 mb-0">
                <div class="col-md-6">
                  Total Cost: {{ orderTotal | currency }}
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">Add to cart</button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
