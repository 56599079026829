<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025GolfCartForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10">
      <h3 align="left">Golf Cart Rentals </h3>
      <p align="left">For your convenience, golf carts are available for rent by reservation. The show site is fairly compact, but some attendees may find that having a golf cart makes it easier to get from the hotel to vehicles or the obedience and draft test areas. The cost for the week is $375 for a 4-passenger cart that converts to a utility bed.  They can be checked out at Show Headquarters beginning Sunday, April 27th  and they must be returned the dedicated area no later than Saturday, May 3rd  immediately following Best of Breed judging. Before reserving a cart, please be advised that they may be used only within the show site on driveways and hard surfaces, must be parked in designated areas (not hotel parking lots) and may not be driven on the road. </p>
      <p align="left"><em>Deadline {{ deadline }}.</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-12">
      <h2 class="mt-3 float-none">Online Golf Cart Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-10 col-sm-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="golfForm" [formGroup]="golfForm" #lf="ngForm">
              <p>Yes, I want to reserve a Golf Cart for the 2025 National Specialty</p>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <!--
                  <tr class="product" data-name="TwoPass" data-sku="106000">
                    <td data-th="Product">
                      <div class="row">
                        <div class="col-sm-10">
                          <h5 class="nomargin">2-Passenger Cart</h5>
                        </div>
                      </div>
                    </td>
                    <td id="twoPassPrice" class="object-price">{{ twoPassCost | currency }}</td>
                    <td data-th="Quantity">
                      <input id="twoPassQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="twoPassQuantity">
                    </td>
                    <td data-th="Subtotal" class="text-center product-subtotal" id="twoPassSub">{{ twoPassTotalCost |
                      currency }}
                    </td>
                  </tr>
                  -->
                  <tr class="product" data-name="FourPass" data-sku="106200">
                    <td data-th="Product">
                      <div class="row">
                        <div class="col-sm-10">
                          <h5 class="nomargin">4-Passenger Cart</h5>
                        </div>
                      </div>
                    </td>
                    <td id="fourPassPrice" class="object-price">{{ fourPassCost | currency }}</td>
                    <td data-th="Quantity">
                      <input id="fourPassQuant" type="number" class="form-control text-center product-quantity"
                             value="0" min="0" (change)="changeQuantity()" formControlName="fourPassQuantity">
                    </td>
                    <td data-th="Subtotal" class="text-center product-subtotal" id="fourPassSub">{{ fourPassTotalCost |
                      currency }}
                    </td>
                  </tr>

                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
