<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/CatalogAdsForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}" target="_top">Email Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>
        CATALOG ADVERTISING <br/>
      </h3>
      <p>Showcase your great dog, share your memories, thank a friend, announce your regional club’s activities or sell your products with an ad in the National Specialty show catalog. Full page B / W ads are $80, color ads are $125, half page ads (no photo) are $30.  Each additional photo on same page are $50 (B / W)  and $100 (color). We reserve the right to reject ads deemed inappropriate.</p>
      <p><em>Deadline: {{ deadline }}. </em></p>
      <h2 class="mt-3 float-none">Online Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-8 col-sm-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="adForm" [formGroup]="adForm" #lf="ngForm">
              <p>Yes, I want to order a catalog ad for the 2025 National Specialty</p>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Select an option</mat-label>
                  <mat-select formControlName="adType" placeholder="Type of Ad" (selectionChange)="changeAdType($event)">
                    <mat-option *ngFor="let adType of adTypes" value="{{ adType.id }}">{{ adType.optionName }}</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.adType">
                    <mat-error class="error-message" *ngIf="adForm.get('adType').hasError(validation.type) && (adForm.get('adType').dirty || adForm.get('adType').touched)">{{validation.message}}</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="shouldUploadImage" class="form-row text-center mx-auto col-md-10 d-block">
                <h3 class="text-start">Upload your Catalog Ad.</h3>
                <p class="text-start">Maximum file size = 4MB. Supported file types: JPG, PNG, and PDF.</p>
                <div>
                  <app-image-upload-custom [max]="1" class="customUploadClass"
                                           [extensions]="['image/jpg','image/png','image/jpeg','application/pdf']"
                                           [url]="'https://images.ncanewfs.org/blob_upload'"
                                           [buttonCaption]="'Select Catalog Ad Image'"
                                           [registrationNumber]=""
                                           (uploadFinished)="onUploadFinished($event)"
                                           [dropBoxMessage]="'Drop an image of your catalog ad here.'"></app-image-upload-custom>
                  <p class="text-danger fw-bold" *ngIf="uploadFailed">{{ uploadResponse }}</p>
                </div>
              </div>

              <div class="col-md-3">
                Product Cost: {{ intItemCost | currency }}
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">Add to cart</button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
   </div>
  </div>
</div>
