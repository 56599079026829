<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025VolunteerForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:volunteers@ncanationalspecialty.org" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10">
      <h3> Volunteers</h3>
      <p align="left">VOLUNTEERS NEEDED . . . There are many aspects of the show that need volunteers, and your help is
        greatly appreciated. If you are willing to share some of your time at the Specialty, please complete the form
        below and the information will be provided to the appropriate chairperson. </p>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="modal-body">
            <form id="initVolunteerForm" [formGroup]="volunteerForm" #lf="ngForm">
              <h4>Yes, I am raising my hand to help at the 2025 National Specialty</h4>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" id="name" name="name" placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="volunteerForm.get('name').hasError(validation.type) && (volunteerForm.get('name').dirty || volunteerForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" id="address" name="address" placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="volunteerForm.get('address').hasError(validation.type) && (volunteerForm.get('address').dirty || volunteerForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" id="emailAddress" name="emailAddress" placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="volunteerForm.get('emailAddress').hasError(validation.type) && (volunteerForm.get('emailAddress').dirty || volunteerForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width-left0">
                  <input matInput type="text" id="dayphone" name="dayphone" placeholder="Daytime Phone" formControlName="dayphone">
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput type="text" id="eveningphone" name="eveningphone" placeholder="Evening Phone" formControlName="eveningphone">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width-left0">
                  <mat-label>Are you an NCA Member?</mat-label>
                  <mat-select formControlName="isMember" placeholder="NCA Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                    <mat-error class="error-message"
                               *ngIf="volunteerForm.get('isMember').hasError(validation.type) && (volunteerForm.get('isMember').dirty || volunteerForm.get('isMember').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <div class="col">
                  <p>Please select all areas that you would be able to help with:</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="catalogsales" name="catalogsales" formControlName="catalogsales">
                    <label for="catalogsales" class="form-check-label">Catalog Sales</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="cgc" name="cgc" formControlName="catalogsales">
                    <label for="cgc" class="form-check-label">CGC</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="trickdog" name="trickdog" formControlName="trickdog">
                    <label for="trickdog" class="form-check-label">Trick Dog</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="communications" name="communications" formControlName="communications">
                    <label for="communications" class="form-check-label">Communications</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="confsteward" name="confsteward" formControlName="confsteward">
                    <label for="confsteward" class="form-check-label">Conformation Steward</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="grounds" name="grounds" formControlName="grounds">
                    <label for="grounds" class="form-check-label">Grounds</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="setup" name="setup" formControlName="setup">
                    <label for="setup" class="form-check-label">Setup/Cleanup</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="draft" name="draft" formControlName="draft">
                    <label for="draft" class="form-check-label">Draft Test Steward</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="auction" name="auction" formControlName="auction">
                    <label for="auction" class="form-check-label">Auction/Brown Bag</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="hospitality" name="hospitality" formControlName="hospitality">
                    <label for="hospitality" class="form-check-label">Hospitality</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="banquet" name="banquet" formControlName="banquet">
                    <label for="hospitality" class="form-check-label">Banquet</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="logo" name="logo" formControlName="logo">
                    <label for="logo" class="form-check-label">Logo Item Sales</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="obediencesteward" name="obediencesteward" formControlName="obediencesteward">
                    <label for="obediencesteward" class="form-check-label">Obedience/Rally Steward</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="registration" name="registration" formControlName="registration">
                    <label for="registration" class="form-check-label">Registration</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="traffic" name="traffic" formControlName="traffic">
                    <label for="traffic" class="form-check-label">Traffic Control</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="trophytable" name="trophytable" formControlName="trophytable">
                    <label for="trophytable" class="form-check-label">Trophy Table</label>
                  </div>
                  <div class="form-check ms-3">
                    <input class="form-check-input me-2" type="checkbox" id="ringfavors" name="ringfavors" formControlName="ringfavors">
                    <label for="ringfavors" class="form-check-label">Ring Favors</label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                    <input matInput type="type" id="daytime" name="daytime" placeholder="Please list the days and times you will be available" formControlName="daytime">
                </mat-form-field>
              </div>
              <div class="row align-items-center text-center">
                <div class="col-md-12">
                  <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

