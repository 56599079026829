<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025DraftForm.pdf" download="">Download Info Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>DRAFT TESTS</h3>
      <p>The Colonial and NewPenDel Newfoundland Clubs are pleased to host the 2025 National Specialty Draft Tests.
        Entries will open and close as published in the respective Draft Test Premium Lists.</p>
      <p>
        Draft test entries will be offered using the Working Dog Online Entry System.  You can download the
        Premium lists and enter the test by clicking on the button below.</p>
      <p>Entries will open and close as published in the Draft Test Premium Lists.</p>
      <p>To receive a Draft Test Premium List, contact Donna Foy, Colonial Newfoundland Club Test Secretary</p>
        <ul>
          <li><a href="mailto:Dlfoy59@aol.com">Dlfoy59@aol.com</a></li>
        </ul>
      <p>or Pat Matthews or Rose Reed, NewPenDel Newfoundland Club Test Secretaries</p>
        <ul>
          <li><a href="mailto:epmatthews@comcast.net">epmatthews@comcast.net</a></li>
          <li><a href="mailto:jandr.reed@comcast.net">jandr.reed@comcast.net</a></li>
        </ul>
      <p><a class="btn btn-info quick_links_action ms-1" href="https://workingentry.ncadogs.org/" target="_blank">
        Enter Online</a></p>
      <p><strong> <em>DO NOT send draft test entries to show superintendent.</em></strong></p>
    </div>
  </div>
</div>
