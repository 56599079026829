import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RollbarService } from '../../services/rollbar.service';
import * as Rollbar from 'rollbar';
import { ApiService } from '../../services/api.service';
import { CartService } from '../../services/cart.service';
import { ProductService } from '../../services/product.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { SponsorModalComponent } from '../../sponsor-modal/sponsor-modal.component';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-regional-trophies',
  templateUrl: './regional-trophies.component.html',
  styleUrls: ['./regional-trophies.component.scss']
})
export class RegionalTrophiesComponent implements OnInit, OnDestroy {

  trophyItems: any[];
  private unsubscribe$ = new Subject<void>();
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  pastDeadline = false;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  addingSpinner: boolean;
  myProductIsChanging: boolean;
  localCart: any;
  isMember = false;
  intId = -1;

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              private activeModal: NgbActiveModal,
              private ngZone: NgZone,
              private api: ApiService,
              private cartService: CartService,
              private productService: ProductService,
              public store: Store<CustomerState>,
              private googleService: GoogleAnalyticsEventsService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.addingSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
    this.cartService.getCart()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.hasOwnProperty('id')) {
          this.localCart = res;
          if (this.localCart.hasOwnProperty('customer')) {
            this.isMember = this.localCart.customer.waId != null;
          } else {
            this.isMember = false;
          }
        } else {
          this.isMember = false;
        }
      });
    this.showSpinner = true;
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {

          this.api.get('/products?category=47&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.trophyItems = res.products;
                      const prod = res.products[0];
                      const availabilityMap = this.cartService.productIsAvailable(prod);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of trophies.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/63')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  isSoldOut(inItem: any) {
    if (this.pastDeadline) {
      return true;
    }
    if (inItem.variants.length === 0) {
      return true;
    } else {
      return inItem.variants[0].soldOut === 1;
    }
  }
  getPrice(inItem: any) {
    let basePrice = 0.0;

    const curtime = new Date().getTime();
    inItem.base_prices.forEach((aPrice) => {
      if (((aPrice.endDate === null) || (aPrice.endDate > curtime)) && (curtime >= aPrice.startDate)) {
        basePrice = parseFloat(aPrice.basePrice);
      }
    });
    return basePrice;
  }
  addToCart(inItem: any) {
    const modalRef = this.modalService.open(SponsorModalComponent, {size: 'lg'});
    modalRef.componentInstance.sponsorship = '';
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.finishCheckout(inItem, receivedEntry);
      modalRef.close();
    });
  }
  finishCheckout(inItem: any, sponsorship: string) {
    const body = {items: []};
    if (inItem.variants.length > 0) {
      const prodVarId = inItem.variants[0].id;
      let sponsorString = '{}';
      if (sponsorship.length > 0) {
        sponsorString = JSON.stringify({dedication: sponsorship});
      }
      const product = {
        productId: inItem.id,
        productVariantId: prodVarId,
        quantity: 1,
        dataPoints: sponsorString
      };
      this.myProductIsChanging = true;
      this.addingSpinner = true;
      this.cartService.addItemToCart(product);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', inItem.name + ':' + prodVarId, 1);
    }
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
