<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025BulkWaterForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12">
      <h3>BOTTLED WATER</h3>
      <p>Purified bottled water is available by pre-order only by the case (6 – 1 gallon bottles/case).  No water will
        be available for purchase at the show site.</p>
      <p>Orders will be available for pick up on Monday, April 28th.  We kindly ask that orders be picked up no later than Wednesday, April 30th.</p>
      <p style="display: none;"><em>Deadline: {{ deadline }}.</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}">Email
        Committee Chair</a>&nbsp;
      </p>
      <h2 class="mt-3 float-none">Online Bulk Water Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
            availabilityDate }}.</h5>
          <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
          <form id="waterForm" [formGroup]="waterForm" #lf="ngForm">
            <p>Yes, I want to reserve bulk cases of water for the 2025 National Specialty</p>
            <div class="form-row">
              <mat-form-field class="inputField-full-width">
                <input matInput placeholder="Contact Number at the National" formControlName="contact">
                <mat-error *ngFor="let validation of accountValidationMessages.contact">
                  <mat-error class="error-message"
                             *ngIf="waterForm.get('contact').hasError(validation.type) && (waterForm.get('contact').dirty || waterForm.get('contact').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="inputField-full-width">
                <input matInput placeholder="Arrival Day/Date" formControlName="arrival">
                <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                  <mat-error class="error-message"
                             *ngIf="waterForm.get('arrival').hasError(validation.type) && (waterForm.get('arrival').dirty || waterForm.get('arrival').touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="mt-3">
              <h5>Water Delivery Preference (Select only ONE option)</h5>
            </div>
            <div class="form-row">
              <div class="col-md-12">
                <mat-radio-group class="water-radio-group" formControlName="deliveryOption">
                  <mat-radio-button class="me-3" type="radio" value="Reserved Grooming">
                    <span>Reserved Grooming Area</span>
                  </mat-radio-button>
                  <mat-form-field class="inputField-full-width ms-4">
                    <input matInput placeholder="Name on Reserved Space" formControlName="resSpace">
                  </mat-form-field>
                  <p class="text-sm-left ms-4">Please verify the name the Reserved space!
                    If the Name entered is not found on a reserved space you will have to pick what the water at the
                    for
                    Pick Up location as noted below</p>
                  <mat-radio-button class="me-3" type="radio" value="Reserved Parking">
                    <span>Reserved Parking Area</span>
                  </mat-radio-button>
                  <mat-radio-button class="me-3" type="radio" value="Pickup">
                    <span>I will pickup water</span>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <p class="mb-0">Please indicate the number of cases of water that you want to order</p>
            <div class="table-responsive">
              <table id="cart" class="table table-hover table-condensed table-water mt-3">
                <thead>
                <tr>
                  <th style="width:40%">Product</th>
                  <th style="width:10%">Price</th>
                  <th style="width:5%">Quantity</th>
                  <th style="width:15%" class="text-center">Subtotal</th>
                </tr>
                </thead>
                <tbody id="modalTableBody">
                <tr class="product" data-name="Cases of Water" data-sku="146000">
                  <td data-th="Product">
                    <div class="row">
                      <div class="col-sm-10">
                        <h4 class="nomargin">Cases of Water</h4><p>(1 case = 6 one gallon jugs)</p>
                      </div>
                    </div>
                  </td>
                  <td id="waterPrice" class="object-price">{{ waterCost | currency }}</td>
                  <td data-th="Quantity">
                    <input id="waterQuant" name="waterQuant" type="number" formControlName="waterQuant"
                           class="form-control text-center product-quantity" value="0" min="0"
                           (change)="changeQuantity()">
                  </td>
                  <td data-th="Subtotal" class="text-center product-subtotal" id="waterSub">{{ waterTotalCost | currency }}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="visible-xs">
                  <td colspan="4" class="text-center">{{ orderTotal | currency }}</td>
                </tr>
                </tfoot>
              </table>
            </div>
            <div class="form-group">
              <div class="col-md-12 text-center">
                <button type="button" (click)="addToCart()" class="btn btn-info text-center" [disabled]="productNotAvailable">Add
                  to cart
                </button>
                <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                  availabilityDate }}.</p>
                <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
