<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025BanquetForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6 col-xs-12">
      <h3> FOOD &amp; DINING EVENTS</h3><br/>
        <p><strong>Saturday Evening Banquet: </strong>Please join us for a celebratory banquet on Saturday, May 3, 2025
          at 6:00 pm in the Grand Ballroom of the Crowne Plaza to celebrate the wonderful week we have just spent
          together, here from the judges, and congratulate the Best of Breed, Best of Opposite, Winners Dog and WInners
          Bitch who will make an appearance to meet attendees at the banquet.  The judges able to stay until Saturday
          will give some remarks on their judging assignment.</p>
      <img src="assets/2025/napkinring.png" class="float-end w-25" alt="Napkin Ring image">
      <p>Through the generosity of Cissy Sullivan, we are again pleased to bring you the fourth and final in the NEWF
        series of commemorative napkin rings.  Tickets are $75 each and will consist of a buffet including salads, meat
        and pasta Italian dishes, roasted vegetables, garlic bread, cakes and pies, soft drinks, water, coffee and tea.
        Only the first 250 banquet tickets purchased will receive a commemorative napkin ring, so don&apos;t hesitate to
        buy your ticket. If you are purchasing more than one banquet ticket, you will need to indicate the names of the
        people attending with you. Tickets must be purchased in advance, and you must be present to receive your napkin rings.</p>
      <p><span class="fw-bold">The Napkin Ring</span>The artistic brainchild, Cissy Sullivan, has donated a different
        napkin ring for  each National since 2022. The &quot;F&quot; ring completes the set.  We ask if you are one of
        the 250 people who are eligible to get a ring and you do not want the gift, that you let Cissy know at the time
        she goes to put it in your hand.  Cissy will make sure it finds a home.  You must pick up your ring individually.
        If you are not in the first 250 people to purchase your banquet ticket and you want an &quot;F&quot; napkin ring,
        there will be a limited supply of all of four pieces for sale outside of the banquet room.  Money raised from
        the sale of these items goes into the Oji Foundation.  </p>
      <p><em>Deadline: {{ deadline }}. </em></p>
      <p><em>Contact:</em><a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;</p>
      <p class="d-none">
        <strong>Saturday Afternoon:</strong> Immediately following Best of Breed judging, raise a glass to celebrate the week’s top winners from the conformation, obedience and rally events as they take a victory lap around the ring. . As available, judges will provide their comments and observations as well as we conclude our week at The Lake. Contacts: Kathy Wortham and Shauna Bryant.
      </p>
    </div>
    <div class="col-md-6 col-sm-12">
      <h2 class="mt-3 float-none">Banquet Tickets Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="modal-body">
            <form id="mealForm" [formGroup]="mealForm" #lf="ngForm">
              <div class="table-responsive">
                <table formArrayName="meals" id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product" *ngFor="let meal of mealVariants;let i = index" [formGroupName]="i">
                    <td data-th="Product">{{ meal.displayName }}</td>
                    <td class="object-price">{{ meal.price | currency}}</td>
                    <td data-th="Quantity">
                      <input type="number" class="form-control text-center product-quantity" value="0" min="0"
                             (change)="changeQuantity(i)" formControlName="quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ meal.subTotal | currency }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12">
                  <div class="form-row">
                    <mat-form-field class="inputField-full-width">
                      <input matInput placeholder="Names of all attendees" formControlName="names">
                      <mat-error *ngFor="let validation of accountValidationMessages.names">
                        <mat-error class="error-message"
                                   *ngIf="mealForm.get('names').hasError(validation.type) && (mealForm.get('names').dirty || mealForm.get('names').touched)">
                          {{validation.message}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field class="inputField-full-width">
                      <input matInput placeholder="Full Address" formControlName="fullAddress">
                      <mat-error *ngFor="let validation of accountValidationMessages.fullAddress">
                        <mat-error class="error-message"
                                   *ngIf="mealForm.get('fullAddress').hasError(validation.type) && (mealForm.get('fullAddress').dirty || mealForm.get('fullAddress').touched)">
                          {{validation.message}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field class="inputField-full-width">
                      <input matInput placeholder="Email Address" formControlName="emailAddress">
                      <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                        <mat-error class="error-message"
                                   *ngIf="mealForm.get('emailAddress').hasError(validation.type) && (mealForm.get('emailAddress').dirty || mealForm.get('emailAddress').touched)">
                          {{validation.message}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-row">
                    <mat-form-field class="inputField-full-width">
                      <input matInput placeholder="Phone Number" formControlName="phone">
                      <mat-error *ngFor="let validation of accountValidationMessages.phone">
                        <mat-error class="error-message"
                                   *ngIf="mealForm.get('phone').hasError(validation.type) && (mealForm.get('phone').dirty || mealForm.get('phone').touched)">
                          {{validation.message}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row shop_page_content d-none">
    <div class="col-md-10 col-sm-12 col-xs-12">
      <h3>MOVIE NIGHT</h3>
      <p>More information will be posted soon.</p>
      <form id="movieForm" [formGroup]="movieForm" #lf2="ngForm">
        <p>Yes, I will be attending the Movie Night at the 2025 National Specialty<br/>
        <div class="col-md-8 col-sm-12 d-none">
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Full Name" formControlName="name">
              <mat-error *ngFor="let validation of accountValidationMessages.name">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('name').hasError(validation.type) && (movieForm.get('name').dirty || movieForm.get('name').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Email Address" formControlName="emailAddress">
              <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('emailAddress').hasError(validation.type) && (movieForm.get('emailAddress').dirty || movieForm.get('emailAddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mt-3 mb-0">
            <mat-form-field class="inputField-full-width">
              <input matInput id="numberInParty" type="number" formControlName="numberInParty"
                     placeholder="Number in your party">
              <mat-error *ngFor="let validation of accountValidationMessages.numberInParty">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('numberInParty').hasError(validation.type) && (movieForm.get('numberInParty').dirty || movieForm.get('numberInParty').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mb-3">
            <mat-form-field class="inputField-full-width">
              <mat-label>Are you an NCA Member?</mat-label>
              <mat-select formControlName="isMember" placeholder="NCA Member?">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
              <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                <mat-error class="error-message"
                           *ngIf="movieForm.get('isMember').hasError(validation.type) && (movieForm.get('isMember').dirty || movieForm.get('isMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
        </div>
      </form>
      <p><em>Deadline: {{ deadline }}.</em></p>
      <p><em>Questions?</em><a class="btn btn-info quick_links_action ms-1"
                                           href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>
      </p>
    </div>
  </div>
</div>
