<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025MemberHotelInfo.pdf">NCA and NCNE Member Reservation Information</a>
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025NonMemberHotelInfo.pdf">Non Member Reservation Information</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>Hotel and Site Information</h3>
      <p id="resform"><br/>
        HOTEL ROOM RESERVATIONS</p>
      <p>No phone or FAX reservations will be accepted. No hand delivery is permitted. Reservations will be accepted
        beginning with January 2, 2025 postmarks and expedited delivery services such as, but not limited to: Express
        Mail, FedEx, UPS, etc.  Reservations received prior to January 2, 2025 will not be accepted. A separate name
        must be provided for each room reserved. Please be considerate! Do not reserve more rooms than needed with the
        idea of canceling those not needed at a later date. A separate sheet is required for each room reserved.</p>

      <p>Your deposit will equal one night&quot;s lodging plus tax. Individual guests may change their reservation up
        until 4/01/25.   Date changes after 4/01/2205 will result in cancellation of entire reservation.
        Cancellations must be received by 4/01/2025. Cancellations received after that date would be subject to a charge
        equal to one night’s room and tax. Once the Crowne Plaza block has been filled, the hotel and NCA will
        coordinate overflow hotel reservations at the nearby Holiday Inn Express Hotel & Suites and other hotels as needed. </p>
      <h3>Additional Information:</h3>
      <ul>
        <li>There is a limit of three (3) dogs allowed per room. The hotel will provide sheets of plastic to place under crates. Guests must protect carpeted areas by placing crates on plastic sheeting.</li>
      </ul>
      <p>
        <li>Dogs are to be crated at all times when the owners are not in the room
        <li>Any guest whose dog makes excessive noise will receive one warning to remedy the situation. If the problem persists after this warning,
          the hotel management reserves the right to remove the offending dog and guest from the hotel.  Housekeepers will not have to service
          rooms in which dogs are loose and unattended.</li>
        <li>There is to be no bathing or grooming in the hotel rooms. All bathing and grooming must be done in designated outdoor areas.</li>
        <li>One additional sheet per bed will be provided for each room occupied by dogs to place over the beds in guestrooms with dogs.</li>
        <li>A cleaning fee of $75 will be assessed for guestrooms occupied by dogs.</li>
        <li>IF THE HOTEL FINDS EXCESSIVE HAIR OR ANY OTHER DAMAGE, THOSE INDIVIDUALS WLL BE BILLED IN EXCESS OF THE CLEANING FEE.</li>
      </p>
    </div>
  </div>
</div>
