<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025CGCForm.pdf" download="">Download CGC And Trick Dog Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-12 col-xs-12">
      <h3>CANINE GOOD CITIZEN TESTING</h3>
      <p>The AKC Canine Good Citizen evaluations will be available at the NCA National Specialty on Friday, May 2,
        2025 starting at 10 a.m. The CGC title certifies that dogs have the training, manners and behaviors to be
        reliable and well-behaved members of families and communities. It can also be a gateway toward Therapy Dog work.</p>
      <p><em>The pre-registration Deadline: {{ cgcDeadline }}.  </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <h3>TRICK DOG TITLE TESTING</h3>
      <p>Do you and your dog love to be the life of the party by showing off their arsenal of tricks? Then your Newf
        might be perfectly primed to earn AKC Trick Dog titles! Since the 1920&quot;s when trick dogs such as Rin Tin
        &amp; Lassie won peoples&quot; hearts, trick dog training has become one of the most exciting new areas in dog
        training today! All levels of testing will be available on Friday, May 2, 2025 starting at 12:00 p.m. </p>
      <p><em>The pre-registration Deadline: {{ tdDeadline }}.  </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
        Committee Chair</a>&nbsp;
      </p>
      <h2 class="mt-3 float-none">Online CGC and Trick Dog Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner || showSpinner2">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner || showSpinner2">
          <div class="col-md-12 col-sm-12">
            <form id="cgcForm" [formGroup]="cgcForm" #lf="ngForm">
              <p>Yes, I want to enter dog(s) in the CGC and Trick Dog Tests at the 2025 National Specialty<br/>
              <div class="table-responsive">
                <table formArrayName="cgcTests" id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr>
                    <th colspan="4">CGC Test Options</th>
                  </tr>
                  <tr class="product" *ngFor="let test of itemVariants;let i = index" [formGroupName]="i">
                    <td data-th="Product">{{ test.displayName }}</td>
                    <td class="object-price">{{ test.price | currency}}</td>
                    <td data-th="Quantity">
                      <input type="number" class="form-control text-center product-quantity" value="0" min="0"
                             (change)="changeCgcQuantity(i)" formControlName="quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ test.subTotal | currency }}</td>
                  </tr>
                  </tbody>
                </table>
              <table formArrayName="tdTests" id="cart2" class="table table-hover table-condensed table-grooming">
                <thead>
                <tr>
                  <th style="width:40%">Product</th>
                  <th style="width:10%">Price</th>
                  <th style="width:5%">Quantity</th>
                  <th style="width:15%" class="text-center">Subtotal</th>
                </tr>
                </thead>
                <tbody id="modalTableBody2">
                <tr>
                  <th colspan="4">Trick Dog Test Options</th>
                </tr>
                <tr class="product" *ngFor="let test of tditemVariants;let i = index" [formGroupName]="i">
                  <td data-th="Product">{{ test.displayName }}</td>
                  <td class="object-price">{{ test.price | currency}}</td>
                  <td data-th="Quantity">
                    <input type="number" class="form-control text-center product-quantity" value="0" min="0"
                           (change)="changeTdQuantity(i)" formControlName="quantity">
                  </td>
                  <td class="text-center product-subtotal">{{ test.subTotal | currency }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="visible-xs">
                  <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                </tr>
                </tfoot>
              </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
