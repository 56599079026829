<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1 disabled" aria-disabled="true" href="assets/2025/2025HealthForms.pdf" download="">Download
          Health Clinic Order Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6">
      <h3>Health Clinics      </h3>
      <p><strong>CARDIOLOGY CLINIC . . . </strong>Stay tuned for more information regarding the planned Cardiology Clinic! </p>
      <p><strong>OPHTHALMOLOGY CLINIC . . . </strong>Stay tuned for more information regarding the planned Ophthalmology Clinic!</p>
      <p><em>Deadline: {{ deadline }}. </em></p>
      <p class="mt-3">DNA TESTING</p>
      <p>Complimentary blood draws for submission to the OFA CHIC DNA repository will once again be performed by licensed
        Veterinarians at the show site. If your dog&apos;s DNA has not been collected, please consider participating in this
        important health clinic to aid future research!</p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}">Email Committee Chair</a>&nbsp;
      </p>
    </div>
    <div class="col-md-6">
      <h2 class="mt-3 float-none">Online Health Clinic Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-8 col-sm-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="hcForm" [formGroup]="hcForm" #lf="ngForm">
              <p>Yes, I want to reserve appointments for the health clinics at the 2025 National Specialty</p>
              <p class="d-none"><span class="text-lg-start fw-bold">Note to NCA Members:</span> NCA members receive a discount price on these clinics so be sure to login (using the button above) to take advantage of the member discount.</p>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Contact Number at the National" formControlName="contact">
                  <mat-error *ngFor="let validation of accountValidationMessages.contact">
                    <mat-error class="error-message"
                               *ngIf="hcForm.get('contact').hasError(validation.type) && (hcForm.get('contact').dirty || hcForm.get('contact').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Cardiology - Auscultation Exam</p>
                        </div>
                      </div>
                    </td>
                    <td id="auscPrice" class="object-price">{{ costOfAuscult | currency }}</td>
                    <td>
                      <input id="auscQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="auscQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ auscCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Cardiology - Echo Cardiogram</p>
                        </div>
                      </div>
                    </td>
                    <td id="echoPrice" class="object-price">{{ costOfEcho | currency }}</td>
                    <td>
                      <input id="echoQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="echoQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ echoCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Opthamology Exam</p>
                        </div>
                      </div>
                    </td>
                    <td id="optPrice" class="object-price">{{ costOfOpt | currency }}</td>
                    <td>
                      <input id="optQuant" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="optQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ optCost | currency }}</td>
                  </tr>

                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
