import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { CartItem } from '../../models/cart';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { ProductService } from 'src/app/services/product.service';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-bulkwater',
  templateUrl: './bulkwater.component.html',
  styleUrls: ['./bulkwater.component.scss']
})
export class BulkwaterComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  waterForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  myProductIsChanging: boolean;
  intItemName = '';
  intItemId = -1;
  waterCost = 0;
  waterTotalCost = 0;
  orderTotal = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadlineTime = 0;
  deadline = '';
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  intId = -1;

  accountValidationMessages = {
    contact: [
      { type: 'required', message: 'Contact information is required' }
    ],
    arrival: [
      { type: 'required', message: 'Your arrival information is required' }
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.waterForm = this.fb.group({
      contact: [{value: '', disabled: false}, Validators.required],
      arrival: [{value: '', disabled: false}, Validators.required],
      deliveryOption: [{value: 'Pickup', disabled: false}],
      resSpace: [{value: '', disabled: false}],
      waterQuant: [{value: 0, disabled: false}]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.api.get('/products?category=30&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;
                      this.waterCost = this.productService.getItemPrice(this.productInfo, 0, this.cartService.isMember);
                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of bulk water products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/51')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeQuantity() {
    const waterQuant = this.waterForm.controls.waterQuant.value;
    this.waterTotalCost = waterQuant * this.waterCost;
    this.orderTotal = this.waterTotalCost;
  }

  addToCart() {
    const waterQuant = this.waterForm.controls.waterQuant.value;
    if (this.waterForm.valid) {
      if (waterQuant === 0) {
        setTimeout(() => {
          console.log('invalid adtype');
          this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
          this.errorModalRef.componentInstance.errorMessage = '';
          this.errorModalRef.componentInstance.longErrorMessage = 'Please select the number of cases of water you' +
            ' would like and try again.';
        }, 500);
        return;
      }
      const body = {items: []};
      const itemArray = body.items;

      // get all grooming spaces
      if (waterQuant > 0) {
        const dPoints = {
          arrival: this.waterForm.controls.arrival.value,
          contact: this.waterForm.controls.contact.value,
          'Delivery Option': this.waterForm.controls.deliveryOption.value,
          'Name on Reserved Space': this.waterForm.controls.resSpace.value,
          displayName: 'Delivery: ' + this.waterForm.controls.deliveryOption.value
        };
        const firstReg: CartItem = {
          productId: this.intItemId, productVarId: -1,
          quantity: waterQuant, dataPoints: JSON.stringify(dPoints)
        };
        itemArray.push(firstReg);
      }
      this.myProductIsChanging = true;
      this.showSpinner = true;
      this.cartService.addMultipleItemsToCart(body);
      this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + 'multiple', 1);
      setTimeout(() => {
        this.myForm.resetForm();
        this.waterForm.reset({
          contact: '',
          arrival: '',
          deliveryOption: 'Pickup',
          resSpace: '',
          waterQuant: 0
        });
      });
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
