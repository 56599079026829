import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { FileHolder } from '../../image-upload/image-upload.component';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-catalog-ads',
  templateUrl: './catalog-ads.component.html',
  styleUrls: ['./catalog-ads.component.scss']
})
export class CatalogAdsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'mailto:dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  adForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  intItemName = '';
  intItemId = -1;
  intItemCost = 0.0;
  basePrice = 0.0;
  adTypes = [];
  myProductIsChanging: boolean;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  uploadFailed = false;
  adUploaded = false;
  adPath = '';
  pdfSrc = '';
  uploadedPDF = false;
  uploadSrc = '';
  uploadResponse = '';
  shouldUploadImage = false;
  intId = -1;

  accountValidationMessages = {
    adType: [
      {type: 'required', message: 'Please select a type of ad'}
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.adForm = this.fb.group({
      adType: [-1, Validators.required]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.api.get('/products?category=5&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      this.productInfo = res.products[0];
                      if (this.productInfo.hasOwnProperty('id')) {
                        this.intItemId = this.productInfo.id;
                      }
                      if (this.productInfo.hasOwnProperty('name')) {
                        this.intItemName = this.productInfo.name;
                      }
                      if (this.productInfo.hasOwnProperty('variants')) {
                        this.adTypes = this.productInfo.variants;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(this.productInfo);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;
                    }
                  }
                  this.showSpinner = false;
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of catalog ad products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/7')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  ngAfterViewInit() {
    window.addEventListener('dragover', (e: DragEvent) => {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e: DragEvent) => {
      e.preventDefault();
    }, false);
  }

  onUploadFinished(file: FileHolder) {
    // check the response
    if (file.serverResponse.response.status === 200) {
      this.uploadFailed = false;
      this.adUploaded = true;
      this.adPath = JSON.parse(file.serverResponse.response.body).filename;
//      this.adPath = file.serverResponse.response.body.replace(/\n/g, '');
      if (file.file.name.endsWith('.pdf')) {
        this.pdfSrc = file.src;
        this.uploadedPDF = true;
      } else {
        this.uploadedPDF = false;
        this.uploadSrc = file.src;
      }
    } else {
      this.adUploaded = false;
      this.adPath = '';
      this.uploadResponse = file.serverResponse.response.error;
      this.uploadFailed = true;
    }
  }

  changeAdType(event) {
    const theSelectedAdType = parseInt(this.adForm.controls.adType.value, 10);
    const theProductSelected = this.adTypes.filter((adType) => adType.id === theSelectedAdType);
    if (theProductSelected.length > 0) {
      const aProd = theProductSelected[0];
      this.intItemCost = this.productService.getItemPrice(this.productInfo, aProd.skuVariant, this.cartService.isMember);
      this.shouldUploadImage = true;
    }
  }

  addToCart() {
    if (this.adForm.invalid) {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
        this.infoModalRef.componentInstance.infoMessage = 'Please select a type of ad and try again.';
      }, 500);
      return;
    }
    let dPoints = {};
    if (this.shouldUploadImage) {
      if (this.adPath === '') {
        setTimeout(() => {
          console.log('missing attachment');
          this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
          this.infoModalRef.componentInstance.infoTitle = 'Unable to add to cart.';
          this.infoModalRef.componentInstance.infoMessage = 'You must upload a catalog ad image before proceeding.';
        }, 500);
        return;
      } else {
        dPoints = {
          imagePath: this.adPath,
          displayName: ''
        };
      }
    }
    const product = {
      productId: this.intItemId,
      productVariantId: parseInt(this.adForm.controls.adType.value, 10),
      quantity: 1,
      dataPoints: JSON.stringify(dPoints)
    };
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addItemToCart(product);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click', this.intItemName + ':' + parseInt(this.adForm.controls.adType.value, 10), 1);
    setTimeout(() => {
      this.myForm.resetForm();
      this.adForm.reset({
        adType: -1
      });
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
