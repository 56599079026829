<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025SeatingForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <h3>
        RESERVED RINGSIDE SEATING </h3>
      <p><br/>
        Enjoy this year&quot;s National Specialty from a comfortable camp chair with folding side table featuring the
        2025 National Specialty logo on the back and customized with your name or kennel name that is yours to keep as
        a memento. Enjoy the show from a prime seat location and watch all the classes and Best of Breed competition
        from your comfortable chair under the tent. The cost of this seating is $200 for Tuesday through Saturday. </p>
      <p><em>Deadline: {{ deadline }} or when 30 chairs have been reserved. </em></p>
      <h2 class="mt-3 float-none">Online Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-8 col-sm-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="seatForm" [formGroup]="seatForm" #lf="ngForm">
              <p>Yes, I want to order reserved seating for the 2025 National Specialty</p>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Name you would like added to each National Specialty Chair" formControlName="name">
                </mat-form-field>
              </div>
              <div class="form-row">
                <div class="col-md-8 d-flex">
                  <label for="num">Number of Seats:</label>
                  <input id="num" type="number" class=" ms-4 text-center product-quantity" value="0" min="0" (change)="changeQuantity()" formControlName="num">
                  <span class="ms-2"> &#64; {{ intItemCost | currency }} each</span>
                </div>
                <div class="col-md-4">
                  Order Total: {{ orderTotal | currency }}
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info add-to-cart-button text-center" [disabled]="productNotAvailable">Add to cart</button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <p><em>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Please email the
        Committee Chair</a></em></p>
    </div>
  </div>
</div>
