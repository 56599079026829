<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>2025 Regional Trophies </h3>
        <div class="row">
          <div class="col-md-9">
            <p>The 2025 Regional Trophies are for the AKC Rally Trial as hosted by Genesee Region Newfoundland Club on Tuesday, April 29 2025.</p>
            <p>The trophies are custom designed medallions on a neck ribbon done by Prairie Dog Pottery.</p>
            <p>Each sponsorship includes four class placements (first through fourth place). i.e. Someone sponsoring Novice A for $65.00 is sponsoring first through fourth place for that class at a total of $65.00.</p>
            <p class="mt-1">Please fill out the sponsorship field carefully, the premium list will be printed from this submission.</p>
            <p class="mt-1">If you have questions, please contact <a href="mailto:{{committeeChairEmail}}">{{ committeeChairName }}</a></p>
            <p>Deadline is {{ deadline }}.</p>
          </div>
          <div class="col-md-3">
            <img src="assets/2025/GRNCLogo.png" class="w-100" alt="GRNC Logo">
          </div>
        </div>
        <p *ngIf="productNotAvailable"><strong>Trophies are not available for sale at this time.</strong></p>
        <p *ngIf="pastDeadline"><strong>Trophies are no longer available for sale</strong></p>
      </header>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <h2>All Classes</h2>
          <table class="table table-bordered table-hover table-sm">
            <tr>
              <td>Name</td>
              <td>Description</td>
              <td>Price</td>
              <td class="text-center">Add to cart</td>
            </tr>
            <tr class="orderRow" *ngFor="let item of trophyItems">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-end">{{ getPrice(item) | currency }}</td>
              <td class="text-center"><button type="button" class="mt-auto mx-auto add-to-cart-button" (click)="addToCart(item)" [disabled]="isSoldOut(item) || productNotAvailable">Add to Cart</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

