<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025ParkingForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-5 col-xs-12">
      <h3>GENERAL PARKING</h3>
      <ul>
        <li>All General/Free Parking, including Spectator Parking, will be in the Hotel’s main parking lot.</li>
        <li>Absolutely no parking will be allowed on any grass areas of the hotel/show grounds.</li>
        <li>X-pens and EZ-ups will not be allowed to take up an extra parking space next to vehicles</li>
        <li>Fire lanes and NO PARKING area will be monitored and vehicles in violation will be towed.</li>
        <li>The parking lot near the show site will be pre-paid parking only.</li>
      </ul>
      <h3>RESERVED PARKING</h3>
      <p>The reserved parking area is a fully paved lot adjacent to the show site, convenient to the grooming tent,
        conformation ring, obedience/rally ring, draft test and rescue parade/event. Having a reserved space will allow
        you unlimited in and out of the parking lot for the entire week without the hassle of having to search for a spot!</p>
	  <h5>Spaces available are:</h5>
      <ul>
        <li>Single space (standard passenger vehicle-sized parking space) $35</li>
        <li>Double (two spaces side by side or front to back, standard sized box truck or passenger van). Please specify front to back or side by side.  $70</li>
        <li>Quad space (four spaces in a square for oversize vehicles.) $140</li>
      </ul>

		<p>Please specify if you would like to be near conformation, obedience/rally ring, draft test/rescue event.</p>
      <p>Reserved Parking spaces will be available on Sunday, April 27, 2025.
        NO trailers or motor homes are permitted. There is no power hook-up provided.</p>
      <p><strong>NCA reserves the right to forcibly enter parked vehicles (locked or unlocked) if a dog inside is
        believed to be in distress. Please note that the parking lots are open to the public and will not be patrolled.
        No overnight camping is permitted.</strong></p>
    <p><em>Deadline: {{ deadline }} </em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
    </div>
    <div class="col-md-7 col-xs-12">
      <h2 class="mt-3 float-none">Online Parking Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
              availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="parkForm" [formGroup]="parkForm" #lf="ngForm">
              <p>Yes, I want to reserve parking for the 2025 National Specialty</p>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Arrival Day/Date" formControlName="arrival">
                  <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('arrival').hasError(validation.type) && (parkForm.get('arrival').dirty || parkForm.get('arrival').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput placeholder="Departure Day/Date" formControlName="departure">
                  <mat-error *ngFor="let validation of accountValidationMessages.departure">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('departure').hasError(validation.type) && (parkForm.get('departure').dirty || parkForm.get('departure').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mb-0">
                <mat-form-field class="inputField-half-width">
                  <input matInput id="emergencyContactPhone" type="text"
                         formControlName="emergencyContactPhone"
                         placeholder="Emergency Contact Phone number">
                  <mat-error *ngFor="let validation of accountValidationMessages.emergencyContactPhone">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('emergencyContactPhone').hasError(validation.type) && (parkForm.get('emergencyContactPhone').dirty || parkForm.get('emergencyContactPhone').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mb-0">
                <label style="margin-left: 2%;">Please place my parking space:</label>
                <mat-form-field class="inputField-half-width">
                  <mat-select formControlName="closerTo">
                    <mat-option value="">Select an Option</mat-option>
                    <mat-option value="conformation">Closer to Conformation Area</mat-option>
                    <mat-option value="ordArea">Closer to Obedience/Rally/Draft Area</mat-option>
                    <mat-option value="dontcare">Don&apos;t have a preference</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.closerTo">
                    <mat-error class="error-message"
                               *ngIf="parkForm.get('closerTo').hasError(validation.type) && (parkForm.get('closerTo').dirty || parkForm.get('closerTo').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row d-none">
                <mat-form-field class="inputField-full-width">
                  <input matInput formControlName="buddy" placeholder="Name of Parking Buddy">
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-half-width">
                  <input matInput formControlName="type" placeholder="Type of Vehicle:">
                </mat-form-field>
                <mat-form-field class="inputField-half-width">
                  <input matInput formControlName="length" placeholder="Length of Vehicle:">
                </mat-form-field>
              </div>
              <div class="table-responsive">
                <table formArrayName="parkTypes" id="cart" class="table table-hover table-condensed table-parking">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:20%">Option</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:10%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product" *ngFor="let type of parkTypes;let i = index" [formGroupName]="i">
                    <td data-th="Product">{{ type.displayName }}</td>
                    <td>{{ getOptionName(type) }}</td>
                    <td class="object-price">{{ type.price | currency}}</td>
                    <td data-th="Quantity">
                      <input type="number" class="form-control text-center product-quantity" value="0" min="0"
                             (change)="changeQuantity(i)" formControlName="quantity">
                    </td>
                    <td class="text-center product-subtotal">{{ type.subTotal | currency }}</td>
                  </tr>

                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
