import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-working-dog-team-relay',
  templateUrl: './working-dog-team-relay.component.html',
  styleUrls: ['./working-dog-team-relay.component.scss']
})
export class WorkingDogTeamRelayComponent implements OnInit {
  committeeChairEmail = '';
  constructor() { }

  ngOnInit(): void {
  }

}
