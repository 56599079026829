<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 mr-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ml-1 disabled" aria-disabled="true" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ml-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6">
      <h3>WORKING DOG FORUM</h3>
      <p>Stay tuned... date and time for this forum will be announced later.</p>
      <div class="d-none">
        <p>The NCA Working Dog Committee invites you to join them for a seminar presenting the latest in equipment used when you and your Newf are drafting. Committee members will be available to answer  any questions you might have on the topic. The seminar requires no pre-registration and is scheduled for 2:30 p.m. on Wednesday, May 15th.&nbsp; Contact: Sue Marino. </p>

        <p>Questions? <a class="btn btn-info quick_links_action ml-1" href="mailto: {{ committeeChairEmail }}" download="">
          Please email the Committee Chair</a>
        </p>
        <h3>OBEDIENCE &amp; RALLY TRIALS </h3>
        <p>Regional Obedience and Rally Trials will be offered and hosted by the Colonial Newfoundland Club on Tuesday, May 14th. The NCA National Obedience and Rally Trial will follow on Wednesday, May 15th. The obedience and rally rings will be OUTSIDE with tenting provided for day crating and seating. NO SOFT SIDED CRATES WILL BE ALLOWED ON THE SHOW GROUNDS INCLUDING IN THE GROOMING TENT, DAY CRATING AREA, RINGSIDE OR ANY OTHER EVENT SITES. Entry information is in the show&rsquo;s premium list and can be found on the show superintendent&rsquo;s website (MB-F) at <a href="http://www.infodog.com">www.infodog.com</a> <br><strong>Entries will close on Wednesday, April 24, 2024 at 12:00 p.m. Eastern time with the Show Superintendent, MB-F. </strong><br><strong>Contacts: Paul Katinas and Janet Harriss.</strong>

        <p> Entry information is in the show&rsquo;s premium list and can be found on the
          <a href="#"> superintendent's website</a>

        <p><em>Questions? </em><a class="btn btn-info quick_links_action ml-1"
                                  href="mailto: {{ committeeChairEmail2 }}" download="">Please email
          Committee Chair</a></p>
        <h3 class="mt-2">Additional Links</h3>
        <p><button type="button" class="btn btn-info quick_links_action wdLinks ml-1" (click)="gotoTrickDog()">TRICK DOG TITLE TESTING</button></p>
        <p><button type="button" class="btn btn-info quick_links_action wdLinks ml-1" (click)="gotoCGC()">CANINE GOOD CITIZEN TESTING</button></p>
        <p><button type="button" class="btn btn-info quick_links_action wdLinks ml-1" (click)="gotoDraft()">DRAFT TESTS</button></p>
        <p><button type="button" class="btn btn-info quick_links_action wdLinks ml-1" (click)="gotoCarting()">SPECIALTY CARTING</button></p>
      </div>
    </div>
    <div class="col-md-6 d-none">
      <div class="wrapper col-md-6">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-10 col-sm-12">
            <form id="wdForm" [formGroup]="wdForm" #lf="ngForm">
              <p>Yes, I am coming to the Working Dog Seminar!</p>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Name" formControlName="name">
                  <mat-error *ngFor="let validation of accountValidationMessages.name">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('name').hasError(validation.type) && (wdForm.get('name').dirty || wdForm.get('name').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Full Address" formControlName="address">
                  <mat-error *ngFor="let validation of accountValidationMessages.address">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('address').hasError(validation.type) && (wdForm.get('address').dirty || wdForm.get('address').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput placeholder="Email Address" formControlName="emailAddress">
                  <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('emailAddress').hasError(validation.type) && (wdForm.get('emailAddress').dirty || wdForm.get('emailAddress').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mt-3 mb-0">
                <mat-form-field class="inputField-half-width">
                  <input matInput id="numberInParty" type="number" formControlName="numberInParty"
                         placeholder="Number in your party">
                  <mat-error *ngFor="let validation of accountValidationMessages.numberInParty">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('numberInParty').hasError(validation.type) && (wdForm.get('numberInParty').dirty || wdForm.get('numberInParty').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Are you an NCA Member?</mat-label>
                  <mat-select formControlName="isMember" placeholder="NCA Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                  <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                    <mat-error class="error-message"
                               *ngIf="wdForm.get('isMember').hasError(validation.type) && (wdForm.get('isMember').dirty || wdForm.get('isMember').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="form-row mb-3">
                <mat-form-field class="inputField-full-width">
                  <mat-label>Are you a member of a Regional Club?</mat-label>
                  <mat-select formControlName="isRCMember" placeholder="Regional Club Member?">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" formControlName="rcName"
                         placeholder="Name of Regional Club">
                </mat-form-field>
              </div>
              <div class="form-row">
                <div class="col">
                  <p>I am a (please check all that apply):</p>
                </div>
              </div>
              <div class="form-row ml-3">
                <div class="col">
                  <input class="me-2" type="checkbox" id="exhibitor" name="exhibitor"
                         formControlName="exhibitor">Working Event Competitor
                </div>
              </div>
              <div class="form-row ml-3">
                <div class="col">
                  <input class="me-2" type="checkbox" id="testCommittee" name="testCommittee"
                         formControlName="testCommittee">Test Committee Member
                </div>
              </div>
              <div class="form-row ml-3">
                <div class="col">
                  <input class="me-2" type="checkbox" id="waterJudge" name="waterJudge"
                         formControlName="waterJudge">NCA Water Rescue Judge
                </div>
              </div>
              <div class="form-row ml-3">
                <div class="col">
                  <input class="me-2" type="checkbox" id="draftJudge" name="draftJudge"
                         formControlName="draftJudge">NCA Draft Judge
                </div>
              </div>
              <div class="form-row">
                <mat-form-field class="inputField-full-width">
                  <input matInput type="text" formControlName="topics"
                         placeholder="Please share the topics you are interested in discussing">
                </mat-form-field>
              </div>
              <button type="button" (click)="sendForm()" class="btn btn-primary">Send Form</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

