<div class="row py-4">
  <div class="col-md-3 m-auto overflow-hidden product-image">
    <p *ngIf="hasMultipleImages" class="text-center" style="font-size:10px;z-index: 10;margin-bottom: 0;"><em>Click on photo to view all images of this product</em></p>
    <img class="card-image" [src]="getIconToDisplay()" (click)="onOpenImageBrowser()">
  </div>
  <div class="col-md-9">
    <img *ngIf="isNew" src="assets/new.png" style="z-index: 1; position: absolute;top: -24px;right: 15px;" class="d-none d-sm-block">
    <img *ngIf="isClearance" src="assets/clearance.png" style="z-index: 1; position: absolute;top: -24px;right: 15px;" class="d-none d-sm-block">
    <div class="card-name">{{ item.name }}</div>
    <div class="card-description" [innerHTML]="item.description"></div>
    <div *ngIf="hasOptions" class="card-options">
      <p class="mb-0">Select Option:</p>
      <select name="option" class="product-color ms-2" (change)="changeOption($event)">
        <option *ngFor="let anOption of optionsArray | keyvalue: sortNull" value="{{anOption.key}}">{{ anOption.value.displayName }}</option>
      </select>
    </div>
    <div *ngIf="!hasOptions && showOptions" class="card-color">
      <p class="mb-0">Select Color:</p>
      <select name="color" #colorSelector class="product-color ms-2" (change)="changeColor($event)">
        <option *ngFor="let color of colorArray | keyvalue: sortNull" value="{{color.value}}">{{ color.key }}</option>
      </select>
    </div>
    <div *ngIf="!hasOptions && showOptions" class="card-size">
      <p class="mb-0">Select Size:</p>
      <select name="size" #sizeSelector class="product-size ms-2" (change)="changeSize($event)">
        <option *ngFor="let size of sizeArray | keyvalue: sortNull" value="{{size.value}}">{{ size.key }}</option>
      </select>
    </div>
    <div class="card-price">Price: {{ getItemPrice() | currency }}
      <span class="text-success ms-5 me-2" *ngIf="memberDiscountApplied">A member discount was applied</span>
      <span class="text-success ms-5 me-2" *ngIf="!memberDiscountApplied && memberDiscountAvailable">A member discount is available. Become a member or login to get this discount</span>
    </div>
    <div>
      <label style="margin-left: 5px;">Enter Quantity:</label>
      <input name="qty" type="text" class="product-quantity-input me-2" [(ngModel)]="prodQuantity" (input)="getQtyValue($event)"/>
      <span class="text-danger">{{ getThresholdText() }}</span>
    </div>
    <div class="position-relative text-center w-50 mt-3 mx-auto">
      <button class="mt-auto mx-auto add-to-cart-button" type="button" (click)="addToCart(item)" [disabled]="productNotAvailable || variantNotAvailable">Add to Cart</button>
      <p *ngIf="variantNotAvailable">This size and/or color is not available or sold out.</p>
      <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{ availabilityDate }}.</p>
      <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
      <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
        <div *ngIf="addingSpinner" class="action-spinner"></div>
    </div>
  </div>
</div>
