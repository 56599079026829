<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>International and Concierge Packages</h3>
        <p>We are excited to offer our international guests from outside North America a selection of all-inclusive
          packages for this year&apos;s show. Whether you&apos;re traveling with or without a dog, we have options to
          make your experience smooth and enjoyable.</p>
        <p>Each package includes registration, tickets to all social and educational events, reserved ringside seating
          in a designated section, and an official show catalog. For those bringing dogs, an additional package option
          includes a reserved grooming space. Weekly rentals of two-person golf carts are also available for added convenience.</p>
        <p>Packages are available for purchase online only. If you're bringing a dog and need assistance with grooming
          equipment, please reach out to the committee chair using the button below</p>
        <h3>Stay tuned for this product to become available for order soon!</h3>
        <p><em>Pre-order Deadline: {{ deadline }}. </em></p>
        <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
          Please email the Committee Chair</a>
        </p>
      </header>
    </div>
  </div>
  <div class="d-none row shop_page_content">
    <div class="col-md-10 col-sm-12 mx-auto">
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card [item]="item" [showOptions]="false"></app-product-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
