<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025RegistrationForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="wrapper">
      <div class="overlay" *ngIf="showSpinner">
        <div class="spinner-wrapper">
          <app-spinner></app-spinner>
        </div>
      </div>
      <div class="row loaded-content" [class.blurred]="showSpinner">
        <div class="col-md-12">
          <header>
            <h3>REGISTRATION</h3>
          </header>
          <p>Registrations are per individual (e.g., a husband and wife require two registrations). The registration fee
            is $45 per adult. Junior (ages 8 to 18) registration fees are $25 for the first junior and $20 for each additional junior in the family.</p>
          <p class="fw-bold">
            <span>Deadline: {{ deadline }}.</span>
          </p>
          <p class="fw-bold mb-4">
            <span>Questions? <a class="btn btn-info quick_links_action ms-1"
                                                  href="mailto: {{ committeeChairEmail }}" download="">
      Please email the Committee Chair</a></span>
          </p>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-8">
            <h5 *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                availabilityDate }}.</h5>
            <h5 *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</h5>
            <form id="initRegForm" [formGroup]="regForm" #lf="ngForm">
              <hr />
              <h2 class="mb-3 text-center float-none">Registration Form</h2>
              <p class="modal-title text-md-left" id="exampleModalLabel">
              </p>
              <div class="form-row mt-3 mb-3">
                <mat-form-field class="inputField-third-width">
                  <input matInput placeholder="Date of Arrival" formControlName="arrival">
                  <mat-error *ngFor="let validation of accountValidationMessages.arrival">
                    <mat-error class="error-message"
                               *ngIf="regForm.get('arrival').hasError(validation.type) && (regForm.get('arrival').dirty || regForm.get('arrival').touched)">
                      {{validation.message}}
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-md-6 border-end border-2">
                  <h3>Adult Registration</h3>
                  <p style="margin-bottom: 5px;">Enter the name to appear on nametags, kennel name, and state and/or country
                    of residence</p>
                  <div class="form-row align-items-center d-flex">
                    <div class="col-md-9">
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Name of First Adult" formControlName="adultHandler1"
                               (change)="calculatePrice()">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Kennel Name" formControlName="adultKennel1">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="State and/or Country" formControlName="adultState1">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-checkbox class="w-100" name="ah1nca" formControlName="ah1nca">NCA Member?
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="form-row align-items-center d-flex">
                    <div class="col-md-9">
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Name of Second Adult" formControlName="adultHandler2"
                               (change)="calculatePrice()">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Kennel Name" formControlName="adultKennel2">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="State and/or Country" formControlName="adultState2">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-checkbox class="w-100" name="ah1nca" formControlName="ah2nca">NCA Member?
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="form-row align-items-center d-flex">
                    <div class="col-md-9">
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Name of Third Adult" formControlName="adultHandler3"
                               (change)="calculatePrice()">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Kennel Name" formControlName="adultKennel3">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="State and/or Country" formControlName="adultState3">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-checkbox class="w-100" name="ah3nca" formControlName="ah1nca">NCA Member?
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <h3>Junior Registration</h3>
                  <p style="margin-bottom: 5px;">Enter the name to appear on nametags, kennel name, and state and/or country
                    or
                    residence</p>
                  <div class="form-row align-items-center d-flex">
                    <div class="col-md-9">
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Name of First Junior" formControlName="juniorHandler1"
                               (change)="calculatePrice()">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Kennel Name" formControlName="juniorKennel1">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="State and/or Country" formControlName="juniorState1">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-checkbox class="inputField-third-width" name="jh1nca" formControlName="jh1nca">NCA Member?
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="form-row align-items-center d-flex">
                    <div class="col-md-9">
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Name of Second Junior" formControlName="juniorHandler2"
                               (change)="calculatePrice()">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Kennel Name" formControlName="juniorKennel2">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="State and/or Country" formControlName="juniorState2">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-checkbox class="inputField-third-width" name="jh2nca" formControlName="jh1nca">NCA Member?
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="form-row align-items-center d-flex">
                    <div class="col-md-9">
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Name of Third Junior" formControlName="juniorHandler3"
                               (change)="calculatePrice()">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="Kennel Name" formControlName="juniorKennel3">
                      </mat-form-field>
                      <mat-form-field class="inputField-third-width">
                        <input matInput placeholder="State and/or Country" formControlName="juniorState3">
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-checkbox class="inputField-third-width" name="jh3nca" formControlName="jh1nca">NCA Member?
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5  table-responsive">
                  <table class="table table-reg" style="text-align: right;">
                    <tr>
                      <td>Adult Registrations:</td>
                      <td class="text-center">{{ adultRegCount}}</td>
                      <td>&#64; {{ adultRegCost | currency }}</td>
                      <td>{{ adultRegTotalCost | currency }}</td>
                    </tr>
                    <tr>
                      <td>First Junior Registrations:</td>
                      <td class="text-center">{{ juniorRegCount > 0 ? 1 : 0 }}</td>
                      <td>&#64; {{ firstJuniorRegCost | currency }}</td>
                      <td>{{ firstJuniorRegTotalCost | currency }}</td>
                    </tr>
                    <tr>
                      <td>Additional Junior Registrations:</td>
                      <td class="text-center">{{ juniorRegCount > 1 ? juniorRegCount - 1 : 0 }}</td>
                      <td>&#64; {{ addJuniorRegCost | currency }}</td>
                      <td>{{ addJuniorRegTotalCost | currency }}</td>
                    </tr>
                    <tr style="border-top: 1px solid black;font-weight: bold;">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>Registration Total</td>
                      <td>{{ regCost | currency }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-4 col-sm-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to
                    cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                      availabilityDate }}</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
