<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-4 me-auto">
      <p class="quick_links">Quick Links:
        <a class="d-none btn btn-info quick_links_action ms-1" href="assets/2025/2025Top20Form.pdf" download="">Download Top 20 Form</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-11 mx-auto">
      <header>
        <h3>TOP 20 / TOP 10 </h3>
        <div class="row">
          <div class="col-md-6 text-center top20ps">
            <p>In keeping with New England history</p>
            <p>and adding in the</p>
            <p>Great Rock &amp; Roll Revolution of the 60&apos;s, let&apos;s all</p>
            <img src="assets/2025/Top20Logo.jpg" alt="Top 20 Logo" class="w-100 mt-3">
          </div>
          <div class="col-md-6">
            <img src="assets/2025/Top20Logo2.png" alt="Second Top 20 Logo" class="w-100">
            <section class="text-center top20ps">
              <p>To <span class="fw-bold">Celebrate</span> the Top 20 Conformation</p>
              <p>and</p>
              <p>Top 10 Obedience Dogs</p>
              <p>of 2024 with a</p>
              <p class="fw-bold">BRITISH INVASION 2025!</p>
            </section>
          </div>
        </div>
        <section class="text-start top20ps">
          <p>We are going to shake things up this year beginning with &apos;sequestered judging&apos; of the Top 20 Conformation dogs. Our secret panel of judges will go over each participating conformation dog in a private location, on site, prior to the evenings main event.
            The panel of judges will include, a Newfoundland breeder, a Professional Handler and an AKC Newfoundland approved judge, in accordance with NCA requirements.
            The judges will choose their Judges Choice award winner from the tallying of scores from their NCA score sheets.</p>
          <ul><li>The Invasion will begin at 7 pm in the Garden Pavilion with a &quot;Meet & Greet&quot;.</li>
          <li>The mood will be a little vintage and a lot of great classic Rock & Roll!</li>
          <li>Cash bars will be available along with a &quot;Slider Station&quot;</li>
          <li>At 8pm our featured guests (the dogs of course) will bring out their best for you, their greatest fans!</li>
          <li>And in following tradition ballots will be tallied to determine <span class="fw-bold fst-italic">People&apos;s Choice.</span></li>
          <li>If you love music and Newfs do not miss this event!</li>
          <li>Your &apos;Ticket to Ride&apos; is $35 which includes admission, good food and event program.</li>
          </ul>
        </section>
        <section class="text-start top20ps mt-3">
          <p><span class="fw-bold">Top 20/10 Logo Items...</span> This year we are pleased to offer a variety of products featuring the 2 different options of &quot;British Invasion&quot; artwork! There is a &quot;Come Together&quot; logo that will be silk-screened on the left chest of apparel and an &quot;Abbey Road&quot; graphic that will be printed Direct-To-Garment of the back of apparel. Please select the logo, color and size of your choice below.</p>
          <p>Please note that colors and sizes are subject to availability which could possibly delay shipping. Every effort will be made to fulfill orders in a timely manner.</p>
          <p>All orders will be processed and shipped by the vendor. There will not be any on-site pick-up available. All merchandise orders will be shipped. International shipping is available. An option for domestic shipping/local pickup will be available to those guests from abroad who are using the International Concierge Program starting in January.</p>
        </section>
      </header>
    </div>
  </div>
  <div class="row d-none">
    <div class="col-md-8 col-sm-12 d-none">
      <form id="topdogForm" [formGroup]="topdogForm" #lf="ngForm">
        <p class="mt-4">Yes, I am interested in attending the Top Dog Celebration at the 2022 National Specialty<br/>
        <div class="form-row">
          <mat-form-field class="inputField-full-width">
            <input matInput placeholder="Full Name" formControlName="name">
            <mat-error *ngFor="let validation of accountValidationMessages.name">
              <mat-error class="error-message"
                         *ngIf="topdogForm.get('name').hasError(validation.type) && (topdogForm.get('name').dirty || topdogForm.get('name').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="inputField-full-width">
            <input matInput placeholder="Email Address" formControlName="emailAddress">
            <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
              <mat-error class="error-message"
                         *ngIf="topdogForm.get('emailAddress').hasError(validation.type) && (topdogForm.get('emailAddress').dirty || topdogForm.get('emailAddress').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row mb-3">
          <div class="col-md-12 col-sm-12 text-center">
            <button type="button" (click)="sendForm()" class="btn btn-info me-5">Send Form</button>Please RSVP by April 1st, 2022
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <h3>Top 20/10 Celebration Items</h3>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner2">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner2">
          <div class="card prodCard" *ngFor="let item of celebItems">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [showOptions]="false" [item]="item"></app-product-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <h3>Embroidery Items</h3>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of embroideredItems">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
          </div>
          <div *ngIf="noProductsAvailable">
            <p>No products available for sale at this time</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row shop_page_content">
    <div class="col-md-10 mx-auto">
      <div class="wrapper">
        <h3>Silk Screen and Direct-To-Garment Items</h3>
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="card prodCard" *ngFor="let item of items">
            <app-product-card (triggerImageBrowserEvent)="openImageBrowser($event)" [item]="item"></app-product-card>
          </div>
          <div *ngIf="noProductsAvailable">
            <p>No products available for sale at this time</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #modalImageBrowser let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div style="flex-direction: row;display: inline-flex;width: 100%;">
      <h2 class="modal-title">Product Image Browser</h2>
      <button type="submit" (click)="d('Close click')" class="close"><span>X</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div>
      <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let image of images">
          <div class="picsum-img-wrapper text-center">
            <img [src]="image" alt="Random slide">
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="d('Close click')" class="btn btn-round-outline">Close</button>
    </div>
  </div>
</ng-template>
