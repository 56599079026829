import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { InfoModalComponent } from '../../info-modal/info-modal.component';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AuctionsComponent implements OnInit, OnDestroy {

  auctionCommitteeChairEmail = 'dan@dogfishsoftware.com';
  auctionCommitteeChairName = 'Dan Zimmerman'
  bbCommitteeChairEmail = 'dan@dogfishsoftware.com';
  bbCommitteeChairName = 'Dan Zimmerman'
  formLink = 'assets/2025/2025_NCA_National_Auction_Form.pdf';
  @ViewChild('lf', {static: true}) myForm: NgForm;
  private unsubscribe$ = new Subject<void>();

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone) { }

  auctionForm: FormGroup;
  showSpinner: boolean;
  formDisabled: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  donationTypes = [{id: 'auction', value: 'Auction'}, {id: 'bb', value: 'Brown Bag'}]
  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ]
  };
  ngOnInit() {
    this.showSpinner = false;
    this.formDisabled = false;
    this.auctionForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      donation: [{value: '', disabled: false}],
      donationType: [{value: 'auction', disabled: false}],
      story: [{value: '', disabled: false}],
      estValue: [{value: '', disabled: false}, Validators.required],
      phone: [{value: '', disabled: false}],
      description: [{value: '', disabled: false}],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]]
    });
    // setTimeout(() => this.auctionForm.disable(), 1000);

    this.api.get('/committees/3')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.auctionCommitteeChairEmail = res.contactEmail;
              this.auctionCommitteeChairName = res.contactName;
            }
          })
        });
    this.api.get('/committees/64')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.bbCommitteeChairEmail = res.contactEmail;
              this.bbCommitteeChairName = res.contactName;
            }
          })
        });
  }
  sendForm() {
    if (this.auctionForm.valid) {
      this.showSpinner = true;
      const donationType = this.auctionForm.controls.donationType.value;

        this.api.post('/forms', {
        committeeId: (donationType === 'auction') ? 3 : 64,
        formData: {
          fullName: this.auctionForm.controls.name.value,
          fullAddress: this.auctionForm.controls.address.value,
          emailAddress: this.auctionForm.controls.emailAddress.value,
          phone: this.auctionForm.controls.phone.value,
          donation: this.auctionForm.controls.donation.value,
          estValue: this.auctionForm.controls.estValue.value,
          story: this.auctionForm.controls.story.value,
          description: this.auctionForm.controls.description.value,
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to submit form.';
        this.infoModalRef.componentInstance.infoMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.auctionForm.reset({
        name: '',
        address: '',
        donation: '',
        donationType: 'auction',
        story: '',
        estValue: '',
        phone: '',
        description: '',
        emailAddress: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Auction coordinator.' +
        ' Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
