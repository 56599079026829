import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import * as customerActions from '../actions/customer.actions';
import { Store } from '@ngrx/store';
import { CustomerState, ShippingAddress } from '../models/customer';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../services/rollbar.service';
import * as customerReducer from '../reducers/customer';
import { InfoModalComponent } from '../info-modal/info-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  memberForm: FormGroup;
  nonMemberForm: FormGroup;
  currentCustomer: CustomerState;
  countryNames: any[];
  countryName: string;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  infoModalRef: NgbModalRef;
  confirmAddress = false;
  holdCustomerState: CustomerState;

  accountValidationMessages = {
    emailMember: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    pwdMember: [
      { type: 'required', message: 'Your password is required' }
    ],
    memaddress: [
      { type: 'required', message: 'Your shipping address is required' }
    ],
    memcity: [
      { type: 'required', message: 'Your city is required' }
    ],
    memstate: [
      { type: 'required', message: 'Your state or province is required' }
    ],
    memzip: [
      { type: 'required', message: 'Your zip code is required' }
    ],
    first: [
      { type: 'required', message: 'Your first name is required' }
    ],
    last: [
      { type: 'required', message: 'Your last name is required' }
    ],
    address: [
      { type: 'required', message: 'Your shipping address is required' }
    ],
    city: [
      { type: 'required', message: 'Your city is required' }
    ],
    state: [
      { type: 'required', message: 'Your state or province is required' }
    ],
    zip: [
      { type: 'required', message: 'Your zip code is required' }
    ],
    phone: [
      { type: 'required', message: 'Your phone number is required' }
    ],
    email: [
      { type: 'required', message: 'Your email address is required' },
      { type: 'email', message: 'Enter a valid email' }
    ]
  };

  constructor(@Inject(RollbarService) private rollbar: Rollbar,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone,
              private cartService: CartService,
              public store: Store<CustomerState>) { }

  ngOnInit() {
    this.currentCustomer = {customer: {
      waId: '', cartId: 0, lastName: '', firstName: '', identity: '', addressline1: '', addressline2: '',
        addressCity: '', addressState: '', addressZip: '', addressCountry: '', email: '', phone: '', maintain: false, isRegionalClubMember: false
      }, token: '', authenticated: false};
    this.holdCustomerState = {customer: {
        waId: '', cartId: 0, lastName: '', firstName: '', identity: '', addressline1: '', addressline2: '',
        addressCity: '', addressState: '', addressZip: '', addressCountry: '', email: '', phone: '', maintain: false, isRegionalClubMember: false
      }, token: '', authenticated: false};
    this.countryNames = ['United States', 'Canada', 'Mexico', 'Australia', 'Austria', 'Belgium', 'Denmark', 'France', 'Germany',
      'Ireland', 'Italy', 'Japan', 'Netherlands', 'New Zealand', 'Switzerland', 'United Kingdom', 'Other'];
    this.countryName = 'United States';

    this.showSpinner = false;
    this.confirmAddress = false;
    this.memberForm = this.fb.group({
      emailMember: ['', Validators.required],
      pwdMember: ['', Validators.required],
      memaddress: [''],
      memcity: [''],
      memstate: [''],
      memzip: [''],
      memcountry: ['United States']
    });
    this.nonMemberForm = this.fb.group({
      first: ['', Validators.required],
      last: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country: ['United States'],
      regClubCode: ['']
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        this.currentCustomer = customer;
      });
    });
  }

  confirmAddressAction() {
    if (this.memberForm.valid) {
      const aCustomer = {
        identity: '',
        firstName: this.holdCustomerState.customer.firstName,
        lastName: this.holdCustomerState.customer.lastName,
        addressline1: this.memberForm.get('memaddress').value,
        addressline2: '',
        addressCity: this.memberForm.get('memcity').value,
        addressState: this.memberForm.get('memstate').value,
        addressZip: this.memberForm.get('memzip').value,
        addressCountry: (this.memberForm.get('memcountry').value === 'US' ? 'United States' : this.memberForm.get('memcountry').value),
        phone: this.holdCustomerState.customer.phone,
        email: this.holdCustomerState.customer.email,
        waId: this.holdCustomerState.customer.waId,
        maintain: this.holdCustomerState.customer.maintain,
        isRegionalClubMember: this.holdCustomerState.customer.isRegionalClubMember,
        cartId: this.holdCustomerState.customer.cartId
      };
      this.showSpinner = true;
      const aShip: ShippingAddress = {
        customer: {
          firstName: aCustomer.firstName,
          lastName: aCustomer.lastName,
          addressLine1: aCustomer.addressline1,
          city: aCustomer.addressCity,
          stateOrProvince: aCustomer.addressState,
          zipOrPostalCode: aCustomer.addressZip,
          country: aCustomer.addressCountry
        }
      }
      this.store.dispatch(new customerActions.SetTokenAction(this.holdCustomerState.token));
      this.store.dispatch(new customerActions.SetAuthenticatedAction(true));
      this.store.dispatch(new customerActions.UpdateCustomerAction(aCustomer));
      this.setupToken();
      this.cartService.cartId = aCustomer.cartId;
      this.cartService.changeShippingAddress(aShip);
      this.activeModal.close();
    }
  }
  loginMember() {
    this.showSpinner = true;
    this.api.post('/members/validate', {email: this.memberForm.controls.emailMember.value,
      password: this.memberForm.controls.pwdMember.value, isAdmin: false, cartId: this.cartService.cartId})
      .subscribe(
        (res) => this.saveLoginData(res),
        (err) => this.manageLoginError(err)
      );
  }

  loginNonMember() {
    this.showSpinner = true;
    this.api.post('/customers/guest/validate', {
      FirstName: this.nonMemberForm.controls.first.value,
      LastName: this.nonMemberForm.controls.last.value,
      Email: this.nonMemberForm.controls.email.value,
      Phone: this.nonMemberForm.controls.phone.value,
      Address: this.nonMemberForm.controls.address.value,
      City: this.nonMemberForm.controls.city.value,
      State: this.nonMemberForm.controls.state.value,
      Zip: this.nonMemberForm.controls.zip.value,
      Country: this.nonMemberForm.controls.country.value,
      RegClubCode: this.nonMemberForm.controls.regClubCode.value,
      CartId: this.cartService.cartId})
      .subscribe(
        (res) => this.saveLoginData(res),
        (err) => this.manageLoginError(err)
      );
  }

  manageLoginError(err) {
    this.ngZone.run(() => {
      this.showSpinner = false;
      setTimeout(() => {
        this.rollbar.info('Login failure: ' + err);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    });
  }

  saveLoginData(res) {
    this.showSpinner = false;
    if (res.valid) {

      if (this.cartService.cartId !== res.cartId) {
        setTimeout(() => {
          this.cartService.cartId = this.currentCustomer.customer.cartId;
          this.rollbar.error('Login failure because CART ID was BAD');
          this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
          this.infoModalRef.componentInstance.infoTitle = 'Warning:';
          this.infoModalRef.componentInstance.infoMessage = 'Your attempt to login was successful, but there was an ' +
            'issue with your existing shopping cart.  You are logged in but' +
            ' we were not able to save the contents of your previous cart.  You will need to start over if you had any items in' +
            ' your cart previously.  We apologize for the inconvenience.';
        }, 500);
      }
      const aCustomer = {
        identity: '',
        firstName: res.first,
        lastName: res.last,
        addressline1: res.address,
        addressline2: '',
        addressCity: res.city,
        addressState: res.state,
        addressZip: res.zip,
        addressCountry: (res.country === 'US' ? 'United States' : res.country),
        phone: res.phone,
        email: res.empty,
        waId: res.myId,
        maintain: (res.hasOwnProperty('maintain')),
        isRegionalClubMember: (res.hasOwnProperty('isRegionalClubMember')),
        cartId: res.cartId};
      this.holdCustomerState.customer = {
        identity: '',
        firstName: res.first,
        lastName: res.last,
        addressline1: res.address,
        addressline2: '',
        addressCity: res.city,
        addressState: res.state,
        addressZip: res.zip,
        addressCountry: (res.country === 'US' ? 'United States' : res.country),
        phone: res.phone,
        email: res.empty,
        waId: res.myId,
        maintain: (res.hasOwnProperty('maintain')),
        isRegionalClubMember: (res.hasOwnProperty('isRegionalClubMember')),
        cartId: res.cartId};
      if (res.hasOwnProperty('myId')) {
        const memAddressControl = this.memberForm.get('memaddress');
        memAddressControl?.setValidators(Validators.required);
        memAddressControl?.setValue(res.address);
        memAddressControl?.updateValueAndValidity()
        const memCityControl = this.memberForm.get('memcity');
        memCityControl?.setValidators(Validators.required);
        memCityControl?.setValue(res.city);
        memCityControl?.updateValueAndValidity()
        const memStateControl = this.memberForm.get('memstate');
        memStateControl?.setValidators(Validators.required);
        memStateControl?.setValue(res.state);
        memStateControl?.updateValueAndValidity()
        const memZipControl = this.memberForm.get('memzip');
        memZipControl?.setValidators(Validators.required);
        memZipControl?.setValue(res.zip);
        memZipControl?.updateValueAndValidity()
        const memCountryControl = this.memberForm.get('memcountry');
        memCountryControl?.setValidators(Validators.required);
        memCountryControl?.setValue(res.country);
        memCountryControl?.updateValueAndValidity()
        this.confirmAddress = true;
        this.holdCustomerState.token = res.token;
      } else {
        this.activeModal.close();
        this.store.dispatch(new customerActions.SetTokenAction(res.token));
        this.store.dispatch(new customerActions.SetAuthenticatedAction(true));
        this.store.dispatch(new customerActions.UpdateCustomerAction(aCustomer));
        this.setupToken();
        this.cartService.cartId = res.cartId;
      }
    } else {
      console.log('login failed');
      setTimeout(() => {
        this.rollbar.info('Login failure for user: ' + res.email + ' with reason: ' + res.reason);
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = 'login.';
        this.errorModalRef.componentInstance.longErrorMessage = '';
      }, 500);
    }
  }

  setupToken() {
    console.log('login.saveData calling setHeaders and passing tokenStr ');
    const tokenStr = 'Bearer ' + this.currentCustomer.token;
    this.api.setAuthToken(tokenStr);

    console.log('saving cookie for later use');
    const date = new Date();
    const knumDays = 30;
    date.setTime(date.getTime() + (knumDays * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = 'natspec2025token=' + this.currentCustomer.token + expires + '; path=/';

    // console.log('token = ' + this.currentCustomer.token);
  }
}
