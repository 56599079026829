<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025SeminarForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto:{{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-8 col-xs-12">
      <h3>HEALTH &amp; LONGEVITY EDUCATION SEMINAR</h3>
     <p>The NCA Health and Longevity Committee is very pleased to have Internationally renowned Canine Physical Therapy,
       Dr. Debbie Torraca speak at our national this year!  Dr. Torraca founded the Wizard of Paws Physical Rehabilitation
       for Animals practice in Colchester CT.  She has authored many chapters in veterinary textbooks, and writes for
       professional journals as well as magazines dedicated to the dog enthusiast. She is a regular contributor for
       Clean Run, Dog Sport, Working Dog Digest, Dogs Naturally, and various breed magazines. You may have seen her on
       Good Morning America or heard her on Ask Martha&apos;s Vet with Marty Goldstein. She has been quoted in many
       newspaper articles, television shows, professional journals, and magazines.  She has recently published ground
       breaking research on Degenerative Myelopathy as well.</p>
       <p>She was a speaker at the Second, Third and Fifth International Symposiums for Veterinarians and Physical
         Therapists and at Brian Mulligan&apos;s First International Symposium on her application of the Mulligan
         principles to dogs and produced an educational dvd on these principles with him.</p>
       <p>She has taught at many veterinary conferences including the American College of Veterinary Surgeons (ACVS),
         North American Veterinary Conference (NAVC), Atlantic Coast Veterinary Conference (ACVC), and many state conferences.</p>
       <p>A long time member of American Physical Therapist Special Interest Group, Debbie has held the office of President.
         She is also a member of the International Veterinary Academy of Pain Management (IVAPM).</p>
       <p>She is one of the founders and core instructors for the first and only university-based program in canine
         physical rehabilitation, University of Tennessee’s Certificate Program in Canine Physical Rehabilitation. She
         teaches throughout the year for this program with live courses and webinars.  She is also currently an instructor
         at the Fenzi Dog Sports Academy and teaches throughout the year.  She leads the Canine Certificate Manual Therapy
         program through the University of Tennessee’s outreach program.</p>
    </div>
    <div class="col-md-4 col-xs-12">
      <h3>Register for Education Seminar</h3>
      <form id="seminarForm" [formGroup]="seminarForm" #lf="ngForm">
        <div class="col-md-12">
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Full Name" formControlName="name">
              <mat-error *ngFor="let validation of accountValidationMessages.name">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('name').hasError(validation.type) && (seminarForm.get('name').dirty || seminarForm.get('name').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="inputField-full-width">
              <input matInput placeholder="Email Address" formControlName="emailAddress">
              <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('emailAddress').hasError(validation.type) && (seminarForm.get('emailAddress').dirty || seminarForm.get('emailAddress').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row mt-3 mb-0">
            <mat-form-field class="inputField-half-width-left0">
              <input matInput id="num" type="number" formControlName="num"
                     placeholder="Number in your party">
              <mat-error *ngFor="let validation of accountValidationMessages.num">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('num').hasError(validation.type) && (seminarForm.get('num').dirty || seminarForm.get('num').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row mb-3">
            <mat-form-field class="inputField-half-width-left0">
              <mat-label>Are you an NCA Member?</mat-label>
              <mat-select formControlName="isMember" placeholder="NCA Member?">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
              <mat-error *ngFor="let validation of accountValidationMessages.isMember">
                <mat-error class="error-message"
                           *ngIf="seminarForm.get('isMember').hasError(validation.type) && (seminarForm.get('isMember').dirty || seminarForm.get('isMember').touched)">
                  {{validation.message}}
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
        </div>
      </form>
    </div>
  </div>
</div>
