import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  private unsubscribe$ = new Subject<void>();

  constructor(private modalService: NgbModal,
              private fb: FormBuilder,
              private api: ApiService,
              private ngZone: NgZone) { }

  vendorForm: FormGroup;
  showSpinner: boolean;
  formDisabled: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    address: [
      { type: 'required', message: 'Your address is required' }
    ]
  };

  ngOnInit() {
    this.showSpinner = false;
    this.formDisabled = false;
    this.vendorForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      address: [{value: '', disabled: false}, Validators.required],
      dayphone: [{value: '', disabled: false}],
      eveningphone: [{value: '', disabled: false}],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      itemTypes: [{value: '', disabled: false}]
    });
    //setTimeout(() => this.vendorForm.disable(), 1000);

    this.api.get('/committees/49')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });

  }

  sendForm() {
    if (this.vendorForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 49,
        formData: {
          fullName: this.vendorForm.controls.name.value,
          fullAddress: this.vendorForm.controls.address.value,
          emailAddress: this.vendorForm.controls.emailAddress.value,
          daytimePhone: this.vendorForm.controls.dayphone.value,
          eveningPhone: this.vendorForm.controls.eveningphone.value,
          itemTypes: this.vendorForm.controls.itemTypes.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        console.log('invalid adtype');
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'Please enter all required fields and try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.vendorForm.reset({
        name: '',
        address: '',
        dayphone: '',
        eveningphone: '',
        emailAddress: '',
        itemTypes: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Vendor coordinator.' +
        ' Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
