import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-rescue',
  templateUrl: './rescue.component.html',
  styleUrls: ['./rescue.component.scss']
})
export class RescueComponent implements OnInit, OnDestroy {

  @ViewChild('lf', {static: true}) myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman';
  private unsubscribe$ = new Subject<void>();
  rescueForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  private infoModalRef: NgbModalRef;
  myProductIsChanging: boolean;
  deadline: string;
  accountValidationMessages = {
    emailAddress: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    name: [
      { type: 'required', message: 'Your name is required' }
    ],
    dogName: [
      { type: 'required', message: 'The name of your dog is required' }
    ],
    dogSex: [
      { type: 'required', message: 'The gender of your dog is required' }
    ],
    dogDOB: [
      { type: 'required', message: 'The Date of Birth of your dog is required' }
    ],
    address: [
      { type: 'required', message: 'Your full address is required' }
    ],
    story: [
      { type: 'required', message: 'The story of your dog is required' }
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private ngZone: NgZone,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = true;
    // deadline is Apti 1
    this.deadline = moment(1743566399000).format('dddd, MMMM Do YYYY');
    this.rescueForm = this.fb.group({
      name: [{value: '', disabled: false}, Validators.required],
      emailAddress: [{value: '', disabled: false}, [Validators.required, Validators.email]],
      address: [{value: '', disabled: false}, Validators.required],
      dogName: [{value: '', disabled: false}, Validators.required],
      dogSex: [{value: '', disabled: false}, Validators.required],
      dogDOB: [{value: '', disabled: false}, Validators.required],
      dayphone: [{value: '', disabled: false}],
      eveningphone: [{value: '', disabled: false}],
      isMember: [''],
      story: [{value: '', disabled: false}, Validators.required]
    });
    this.api.get('/committees/35')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  sendForm() {
    if (this.rescueForm.valid) {
      this.showSpinner = true;
      this.api.post('/forms', {
        committeeId: 35,
        formData: {
          fullName: this.rescueForm.controls.name.value,
          fullAddress: this.rescueForm.controls.address.value,
          emailAddress: this.rescueForm.controls.emailAddress.value,
          ncaMember: this.rescueForm.controls.isMember.value,
          dayphone: this.rescueForm.controls.dayphone.value,
          eveningphone: this.rescueForm.controls.eveningphone.value,
          dogName: this.rescueForm.controls.dogName.value,
          dogSex: this.rescueForm.controls.dogSex.value,
          dogDOB: this.rescueForm.controls.dogDOB.value,
          story: this.rescueForm.controls.story.value
        }})
        .subscribe(
          (res) => this.formSent(res),
          (err) => this.formSendFailed(err)
        );
    } else {
      setTimeout(() => {
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoTitle = 'Unable to submit form.';
        this.infoModalRef.componentInstance.infoMessage = 'You have not filled out the form completely.  Please try again.';
      }, 500);
    }
  }

  private formSent(res: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.myForm.resetForm();
      this.rescueForm.reset({
        name: '',
        emailAddress: '',
        address: '',
        dogName: '',
        dogSex: '',
        dogDOB: '',
        dayphone: '',
        eveningphone: '',
        isMember: '',
        story: ''
      });
      this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
      this.infoModalRef.componentInstance.infoTitle = 'Message Sent.';
      this.infoModalRef.componentInstance.infoMessage = 'Your message was successfully sent to the Rescue Committee coordinator.' +
        '  Thank you.';
    }, 500);
  }

  private formSendFailed(err: any) {
    this.showSpinner = false;
    setTimeout(() => {
      this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
      this.errorModalRef.componentInstance.errorMessage = '';
      this.errorModalRef.componentInstance.longErrorMessage = 'An error occurred while submitting this form.  Please try again.';
    }, 500);
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
