import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Registration } from '../../models/registration';
import { CartService } from '../../services/cart.service';
import { CartItem } from '../../models/cart';
import { takeUntil } from 'rxjs/operators';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import { ApiService } from '../../services/api.service';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ProductService } from '../../services/product.service';
import { InfoModalComponent } from '../../info-modal/info-modal.component';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @ViewChild('lf') myForm: NgForm;
  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  regForm: FormGroup;
  showSpinner: boolean;
  accountValidationMessages = {
    arrival: [
      { type: 'required', message: 'Your arrival date is required' }
    ]
  };
  private unsubscribe$ = new Subject<void>();
  infoModalRef: NgbModalRef;
  errorModalRef: NgbModalRef;
  registrations: Registration[] = [];
  adultRegCount = 0;
  juniorRegCount = 0;
  adultRegCost = 0;
  firstJuniorRegCost = 0;
  addJuniorRegCost = 0;
  adultRegTotalCost = 0;
  firstJuniorRegTotalCost = 0;
  addJuniorRegTotalCost = 0;
  memberDiscountApplied = false;
  memberDiscountAvailable = false;
  productInfo: any;
  adultItemId = -1;
  firstJuniorItemId = -1;
  addJuniorItemId = -1;
  regCost = 0;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  intId = -1;

  constructor(private fb: FormBuilder,
              private api: ApiService,
              private productService: ProductService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private modalService: NgbModal,
              private cartService: CartService) { }

  ngOnInit() {
    this.showSpinner = false;
    this.adultRegCount = 0;
    this.juniorRegCount = 0;
    this.registrations = [{nameOfRegistrant: '', kennelName: '', stateOrCountry: '', ncaMember: false},
      {nameOfRegistrant: '', kennelName: '', stateOrCountry: '', ncaMember: false},
      {nameOfRegistrant: '', kennelName: '', stateOrCountry: '', ncaMember: false},
      {nameOfRegistrant: '', kennelName: '', stateOrCountry: '', ncaMember: false},
      {nameOfRegistrant: '', kennelName: '', stateOrCountry: '', ncaMember: false},
      {nameOfRegistrant: '', kennelName: '', stateOrCountry: '', ncaMember: false}];
    this.regForm = this.fb.group({
      arrival: [{value: '', disabled: false}, Validators.required],
      adultHandler1: [{value: '', disabled: false}],
      adultKennel1: [{value: '', disabled: false}],
      adultState1: [{value: '', disabled: false}],
      ah1nca: [{value: false, disabled: false}],
      adultHandler2: [{value: '', disabled: false}],
      adultKennel2: [{value: '', disabled: false}],
      adultState2: [{value: '', disabled: false}],
      ah2nca: [{value: false, disabled: false}],
      adultHandler3: [{value: '', disabled: false}],
      adultKennel3: [{value: '', disabled: false}],
      adultState3: [{value: '', disabled: false}],
      ah3nca: [{value: false, disabled: false}],
      juniorHandler1: [{value: '', disabled: false}],
      juniorKennel1: [{value: '', disabled: false}],
      juniorState1: [{value: '', disabled: false}],
      jh1nca: [{value: false, disabled: false}],
      juniorHandler2: [{value: '', disabled: false}],
      juniorKennel2: [{value: '', disabled: false}],
      juniorState2: [{value: '', disabled: false}],
      jh2nca: [{value: false, disabled: false}],
      juniorHandler3: [{value: '', disabled: false}],
      juniorKennel3: [{value: '', disabled: false}],
      juniorState3: [{value: '', disabled: false}],
      jh3nca: [{value: false, disabled: false}]
    });

    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {
          this.api.get('/products?category=4&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      res.products.forEach((product) => {
                        if (product.hasOwnProperty('baseSkuNumber')) {
                          let baseSkuNumber = product.baseSkuNumber;
                          switch (baseSkuNumber) {
                            case 102200:
                              if (product.hasOwnProperty('id')) {
                                this.adultItemId = product.id;
                              }
                              // now check availability
                              const availabilityMap = this.cartService.productIsAvailable(product);
                              this.productNotAvailable = availabilityMap.productNotAvailable;
                              this.availabilityDate = availabilityMap.availabilityDate;
                              this.availabilityDateTime = availabilityMap.availabilityDateTime;
                              this.deadlineTime = availabilityMap.deadlineTime;
                              this.deadline = availabilityMap.deadline;
                              this.comingSoon = availabilityMap.comingSoon;
                              this.soldOut = availabilityMap.soldOut;

                              this.adultRegCost = this.getBasePrice(product);
                              break;
                            case 102600:
                              if (product.hasOwnProperty('id')) {
                                this.firstJuniorItemId = product.id;
                              }
                              this.firstJuniorRegCost = this.getBasePrice(product);
                              break;
                            case 102800:
                              if (product.hasOwnProperty('id')) {
                                this.addJuniorItemId = product.id;
                              }
                              this.addJuniorRegCost = this.getBasePrice(product);
                              break;
                          }
                        }
                      })
                      this.showSpinner = false;

                    }
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of registration products.';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.api.get('/committees/34')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.ngZone.run(() => {
            this.showSpinner = false;
            if (res.hasOwnProperty('contactName')) {
              this.committeeChairEmail = res.contactEmail;
              this.committeeChairName = res.contactName;
            }
          })
        });
  }

  getBasePrice(product) {
    let basePrice = 0.0;

    const curtime = new Date().getTime();
    product.base_prices.forEach((aPrice) => {
      if (((aPrice.endDate === null) || (aPrice.endDate > curtime)) && (curtime >= aPrice.startDate)) {
        basePrice = parseFloat(aPrice.basePrice);
      }
    });
    return basePrice;
  }

  addToCart() {
    if (this.regForm.valid) {
      const body = {items: []};
      const itemArray = body.items;
      // get all registrants
      const reg1 = this.regForm.controls.adultHandler1.value;
      if (reg1.length > 0) {
        const dPoints = {arrivalDate: this.regForm.controls.arrival.value,
          'Name to appear on nametag': reg1,
          'Kennel Name': this.regForm.controls.adultKennel1.value,
          'State and/or Country': this.regForm.controls.adultState1.value,
          'NCA Member': this.regForm.controls.ah1nca.value,
          displayName: reg1
        };
        const firstReg: CartItem = {productId: this.adultItemId, productVarId: -1, quantity: 1, dataPoints: JSON.stringify(dPoints)};
        itemArray.push(firstReg);
      }
      const reg2 = this.regForm.controls.adultHandler2.value;
      if (reg2.length > 0) {
        const dPoints = {arrivalDate: this.regForm.controls.arrival.value,
          'Name to appear on nametag': reg2,
          'Kennel Name': this.regForm.controls.adultKennel2.value,
          'State and/or Country': this.regForm.controls.adultState2.value,
          'NCA Member': this.regForm.controls.ah2nca.value,
          displayName: reg2
        };
        const secondReg: CartItem = {productId: this.adultItemId, productVarId: -1, quantity: 1, dataPoints: JSON.stringify(dPoints)};
        itemArray.push(secondReg);
      }
      const reg3 = this.regForm.controls.adultHandler3.value;
      if (reg3.length > 0) {
        const dPoints = {arrivalDate: this.regForm.controls.arrival.value,
          'Name to appear on nametag': reg3,
          'Kennel Name': this.regForm.controls.adultKennel3.value,
          'State and/or Country': this.regForm.controls.adultState3.value,
          'NCA Member': this.regForm.controls.ah3nca.value,
          displayName: reg3
        };
        const thirdReg: CartItem = {productId: this.adultItemId, productVarId: -1, quantity: 1, dataPoints: JSON.stringify(dPoints)};
        itemArray.push(thirdReg);
      }

      const jreg1 = this.regForm.controls.juniorHandler1.value;
      if (jreg1.length > 0) {
        const dPoints = {arrivalDate: this.regForm.controls.arrival.value,
          'Name to appear on nametag': jreg1,
          'Kennel Name': this.regForm.controls.juniorKennel1.value,
          'State and/or Country': this.regForm.controls.juniorState1.value,
          'NCA Member': this.regForm.controls.jh1nca.value,
          displayName: jreg1
        };
        const jfirstReg: CartItem = {productId: this.firstJuniorItemId, productVarId: -1, quantity: 1, dataPoints: JSON.stringify(dPoints)};
        itemArray.push(jfirstReg);
      }
      const jreg2 = this.regForm.controls.juniorHandler2.value;
      if (jreg2.length > 0) {
        const dPoints =  {arrivalDate: this.regForm.controls.arrival.value,
          'Name to appear on nametag': jreg2,
          'Kennel Name': this.regForm.controls.juniorKennel2.value,
          'State and/or Country': this.regForm.controls.juniorState2.value,
          'NCA Member': this.regForm.controls.jh2nca.value,
          displayName: jreg2
        };
        const jsecondReg: CartItem = {productId: this.addJuniorItemId, productVarId: -1, quantity: 1, dataPoints: JSON.stringify(dPoints)};
        itemArray.push(jsecondReg);
      }
      const jreg3 = this.regForm.controls.juniorHandler3.value;
      if (jreg3.length > 0) {
        const dPoints = {arrivalDate: this.regForm.controls.arrival.value,
          'Name to appear on nametag': jreg3,
          'Kennel Name': this.regForm.controls.juniorKennel3.value,
          'State and/or Country': this.regForm.controls.juniorState3.value,
          'NCA Member': this.regForm.controls.jh3nca.value,
          displayName: jreg3
        };
        const jthirdReg: CartItem = {productId: this.addJuniorItemId, productVarId: -1, quantity: 1, dataPoints: JSON.stringify(dPoints)};
        itemArray.push(jthirdReg);
      }

      this.cartService.addMultipleItemsToCart(body);
      setTimeout(() => {
        this.myForm.resetForm();
        this.regForm.reset({
          arrival: '',
          adultHandler1: '',
          adultKennel1: '',
          adultState1: '',
          ah1nca: false,
          adultHandler2: '',
          adultKennel2: '',
          adultState2: '',
          ah2nca: false,
          adultHandler3: '',
          adultKennel3: '',
          adultState3: '',
          ah3nca: false,
          juniorHandler1: '',
          juniorKennel1: '',
          juniorState1: '',
          jh1nca: false,
          juniorHandler2: '',
          juniorKennel2: '',
          juniorState2: '',
          jh2nca: false,
          juniorHandler3: '',
          juniorKennel3: '',
          juniorState3: '',
          jh3nca: false
        });
        this.adultRegTotalCost = 0;
        this.firstJuniorRegTotalCost = 0;
        this.addJuniorRegTotalCost = 0;
        this.regCost = 0;
        this.adultRegCount = 0;
        this.juniorRegCount = 0;
      });
    } else {
      setTimeout(() => {
        console.log('did not enter arrival date');
        this.infoModalRef = this.modalService.open(InfoModalComponent, {size: 'lg'});
        this.infoModalRef.componentInstance.infoMessage = 'Please enter the date of your arrival and try again.';
        this.infoModalRef.componentInstance.infoTitle = 'Could not complete order.';
      }, 500);
      return;
    }
  }

  calculatePrice() {
    this.adultRegCount = (this.regForm.controls.adultHandler1.value.length > 0 ? 1 : 0) +
      (this.regForm.controls.adultHandler2.value.length > 0 ? 1 : 0) +
      (this.regForm.controls.adultHandler3.value.length > 0 ? 1 : 0);
    this.juniorRegCount = (this.regForm.controls.juniorHandler1.value.length > 0 ? 1 : 0) +
      (this.regForm.controls.juniorHandler2.value.length > 0 ? 1 : 0) +
      (this.regForm.controls.juniorHandler3.value.length > 0 ? 1 : 0);
    this.adultRegTotalCost = this.adultRegCount * this.adultRegCost;
    this.firstJuniorRegTotalCost = (this.juniorRegCount > 0 ? this.firstJuniorRegCost : 0);
    this.addJuniorRegTotalCost = (this.juniorRegCount > 1 ? (this.addJuniorRegCost * (this.juniorRegCount - 1)) : 0);
    this.regCost = this.adultRegTotalCost + this.firstJuniorRegTotalCost + this.addJuniorRegTotalCost;
  }
}
