import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { ShopComponent } from '../shop/shop.component';
import { LogoComponent } from './logo/logo.component';
import { Top20Component } from './top20/top20.component';
import { GolfcartComponent } from './golfcart/golfcart.component';
import { BulkwaterComponent } from './bulkwater/bulkwater.component';
import { RvparkingComponent } from './rvparking/rvparking.component';
import { MainComponent } from './main/main.component';
import { PacketComponent } from './packet/packet.component';
import { FormsComponent } from './forms/forms.component';
import { HotelComponent } from './hotel/hotel.component';
import { ShowSuperComponent } from './show-super/show-super.component';
import { CatalogAdsComponent } from './catalog-ads/catalog-ads.component';
import { VendorsComponent } from './vendors/vendors.component';
import { OpeningComponent } from './opening/opening.component';
import { MeetingComponent } from './meeting/meeting.component';
import { JuniorsComponent } from './juniors/juniors.component';
import { GroomingComponent } from './grooming/grooming.component';
import { SeatingComponent } from './seating/seating.component';
import { ParkingComponent } from './parking/parking.component';
import { MealsComponent } from './meals/meals.component';
import { HealthComponent } from './health/health.component';
import { SeminarsComponent } from './seminars/seminars.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { WorkingDogForumComponent } from './working-dog-forum/working-dog-forum.component';
import { RescueComponent } from './rescue/rescue.component';
import { HonorsComponent } from './honors/honors.component';
import { LivingLegendsComponent } from './living-legends/living-legends.component';
import { CartingComponent } from './carting/carting.component';
import { DraftComponent } from './draft/draft.component';
import { CgcComponent } from './cgc/cgc.component';
import { PostShowComponent } from './post-show/post-show.component';
import { DirectoryComponent } from './directory/directory.component';
import { LovettComponent } from './lovett/lovett.component';
import { InternationalComponent } from './international/international.component';
import { TrophiesComponent } from './trophies/trophies.component';
import { VintageComponent } from './vintage/vintage.component';
import { VipComponent } from './vip/vip.component';
import { VolunteersComponent } from './volunteers/volunteers.component';
import { PostShowMerchComponent } from './post-show-merch/post-show-merch.component';
import { RegionalTrophiesComponent } from './regional-trophies/regional-trophies.component';
import { WorkingDogTeamRelayComponent } from './working-dog-team-relay/working-dog-team-relay.component';

const  routes: Routes  = [
  {
    path:  'shop',
    component:  ShopComponent,
    children: [
      {
        path: '',
        component: MainComponent
      },
      {
        path: 'main',
        component: MainComponent
      },
      {
        path: 'packet',
        component: PacketComponent
      },
      {
        path: 'forms',
        component: FormsComponent
      },
      {
        path: 'hotel',
        component: HotelComponent
      },
      {
        path: 'showsuper',
        component: ShowSuperComponent
      },
      {
        path:  'register',
        component:  RegisterComponent
      },
      {
        path: 'logo',
        component: LogoComponent
      },
      {
        path: 'top20',
        component: Top20Component
      },
      {
        path: 'catalogAds',
        component: CatalogAdsComponent
      },
      {
        path: 'vendors',
        component: VendorsComponent
      },
      {
        path: 'opening',
        component: OpeningComponent
      },
      {
        path: 'meeting',
        component: MeetingComponent
      },
      {
        path: 'juniors',
        component: JuniorsComponent
      },
      {
        path: 'grooming',
        component: GroomingComponent
      },
      {
        path: 'seating',
        component: SeatingComponent
      },
      {
        path: 'parking',
        component: ParkingComponent
      },
      {
        path: 'golfcart',
        component: GolfcartComponent
      },
      {
        path: 'bulkwater',
        component: BulkwaterComponent
      },
      {
        path: 'rvparking',
        component: RvparkingComponent
      },
      {
        path: 'meals',
        component: MealsComponent
      },
      {
        path: 'health',
        component: HealthComponent
      },
      {
        path: 'seminars',
        component: SeminarsComponent
      },
      {
        path: 'auctions',
        component: AuctionsComponent
      },
      {
        path: 'wdcforum',
        component: WorkingDogForumComponent
      },
      {
        path: 'rescue',
        component: RescueComponent
      },
      {
        path: 'honors',
        component: HonorsComponent
      },
      {
        path: 'livlegs',
        component: LivingLegendsComponent
      },
      {
        path: 'carting',
        component: CartingComponent
      },
      {
        path: 'draft',
        component: DraftComponent
      },
      {
        path: 'cgc',
        component: CgcComponent
      },
      {
        path: 'postshow',
        component: PostShowComponent
      },
      {
        path: 'postshowmerch',
        component: PostShowMerchComponent
      },
      {
        path: 'directory',
        component: DirectoryComponent
      },
      {
        path: 'lovett',
        component: LovettComponent
      },
      {
        path: 'international',
        component: InternationalComponent
      },
      {
        path: 'trophies',
        component: TrophiesComponent
      },
      {
        path: 'vintage',
        component: VintageComponent
      },
      {
        path: 'vip',
        component: VipComponent
      },
      {
        path: 'volunteers',
        component: VolunteersComponent
      },
      {
        path: 'regionaltrophies',
        component: RegionalTrophiesComponent
      },
      {
        path: 'wdcteam',
        component: WorkingDogTeamRelayComponent
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export  class  ShoppingRoutingModule { }
