<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="d-none btn btn-info quick_links_action ms-1" href="assets/2025/VIPForm.pdf" download="">Download Form</a>
        <a class="d-none btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-6 table-responsive">
      <h3>
        VIP Sponsorship Packages</h3>
      <h5>Head east to Rhode Island and join us for an exciting national! Our inaugural VIP Sponsorship program in 2024 was a resounding success, and we’re thrilled to bring it back for 2025!  Become a VIP National Specialty Sponsor during this limited time opportunity running only November 1 through December 30, 2024.</h5>
      <p>This exclusive program offers you early access to hotel reservations and bundles all the must-have items into one seamless package. Imagine the convenience of picking up everything you need in one spot, thanks to our dedicated VIP Concierge, Tami Palomba. She’ll have your reserved items and tickets ready for you, making your experience as smooth as possible. Don’t miss out on this fantastic opportunity to elevate your experience and support the national specialty!</p>
      <p>Once you secure your VIP Sponsorship, Tami will reach out with the hotel reservation form and provide confirmation of the items included in your package.</p>
      <table class="table table-bordered vip-table">
        <tr>
          <th>Included Products</th>
          <th>Bronze</th>
          <th>Silver</th>
          <th>Gold</th>
        </tr>
        <tr>
          <td>Advanced Hotel Reservations</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Registrations</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>VIP Reserved Parking (18&apos; spot)</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Reserved Ringside Seating with customized chair</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>2-Seat Golf Cart</td>
          <td></td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Catalogs</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Banquet Tickets</td>
          <td>1</td>
          <td>1</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Brown Bag Raffle Tickets</td>
          <td>50</td>
          <td>100</td>
          <td>100</td>
        </tr>
        <tr>
          <td>Top 20/10 Reserved Seating & Catalog</td>
          <td></td>
          <td>1</td>
          <td>2</td>
        </tr>
      </table>
      <p><em>Deadline: {{ deadline }}.</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
    </div>
    <div class="col-md-6">
      <h2 class="mt-3 float-none">Online VIP Package Order Form</h2>
      <div class="wrapper">
        <div class="overlay" *ngIf="showSpinner">
          <div class="spinner-wrapper">
            <app-spinner></app-spinner>
          </div>
        </div>

        <div class="loaded-content" [class.blurred]="showSpinner">
          <div class="col-md-12 col-sm-12">
            <form id="vipForm" [formGroup]="vipForm" #lf="ngForm">
              <p>Yes, I want to order VIP Packages from the 2025 National Specialty</p>
              <div class="table-responsive">
                <table id="cart" class="table table-hover table-condensed table-grooming">
                  <thead>
                  <tr>
                    <th style="width:40%">Product</th>
                    <th style="width:10%">Price</th>
                    <th style="width:5%">Quantity</th>
                    <th style="width:15%" class="text-center">Subtotal</th>
                  </tr>
                  </thead>
                  <tbody id="modalTableBody">
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Bronze Level Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="vipbrice" class="object-price">{{ vipbCost | currency }}</td>
                    <td>
                      <input id="vipbQuantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="vipbQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ vipbTotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Silver Level Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="vipsPrice" class="object-price">{{ vipsCost | currency }}</td>
                    <td>
                      <input id="vipsQuantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="vipsQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ vipsTotalCost | currency }}</td>
                  </tr>
                  <tr class="product">
                    <td>
                      <div class="row">
                        <div class="col-sm-10">
                          <p>Gold Level Package</p>
                        </div>
                      </div>
                    </td>
                    <td id="vipgPrice" class="object-price">{{ vipgCost | currency }}</td>
                    <td>
                      <input id="vipgQuantity" type="number" class="form-control text-center product-quantity" value="0"
                             min="0" (change)="changeQuantity()" formControlName="vipgQuantity">
                    </td>
                    <td class="text-center product-subtotal">{{ vipgTotalCost | currency }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="visible-xs">
                    <td colspan="4" id="sm-order-total" class="text-center">{{ orderTotal | currency }}</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <div class="form-group">
                <div class="col-md-12 text-center">
                  <button type="button" (click)="addToCart()" class="btn btn-info quick_links_action text-center" [disabled]="productNotAvailable">Add
                    to cart
                  </button>
                  <p *ngIf="productNotAvailable && comingSoon">This product will be available for sale on {{
                    availabilityDate }}.</p>
                  <p *ngIf="productNotAvailable && !comingSoon">This product is not available for sale.</p>
                  <p *ngIf="productNotAvailable && soldOut">This product is SOLD OUT.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
