import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorModalComponent } from '../../error-modal/error-modal.component';
import * as moment from 'moment-timezone';
import { Store } from '@ngrx/store';
import { CustomerState } from '../../models/customer';
import * as customerReducer from '../../reducers/customer';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  committeeChairEmail = 'dan@dogfishsoftware.com';
  committeeChairName = 'Dan Zimmerman'
  donForm: FormGroup;
  showSpinner: boolean;
  errorModalRef: NgbModalRef;
  private unsubscribe$ = new Subject<void>();
  intItemName = '';
  intItemId = -1;
  intItemCost = 0.0;
  basePrice = 0.0;
  donAmounts = [];
  myProductIsChanging: boolean;
  deadline = '';
  deadlineTime = 0;
  productNotAvailable = true;
  soldOut = false;
  comingSoon = false;
  availabilityDate = '';
  availabilityDateTime = 0;
  intId = -1;

  accountValidationMessages = {
    donAmount: [
      {type: 'required', message: 'Please select an amount to donate'}
    ]
  };

  constructor(private fb: FormBuilder,
              private cartService: CartService,
              private api: ApiService,
              private ngZone: NgZone,
              public store: Store<CustomerState>,
              private  modalService: NgbModal,
              private googleService: GoogleAnalyticsEventsService) { }

  ngOnInit() {
    this.myProductIsChanging = false;
    this.showSpinner = false;
    this.donForm = this.fb.group({
      donAmount: [-1, Validators.required]
    });
    this.store.select(customerReducer.getCustomer).subscribe((customer: CustomerState) => {
      this.ngZone.run(() => {
        if ((customer.token !== '') && (this.intId === -1)) {

          this.api.get('/products?category=32&variants=true')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                this.ngZone.run(() => {
                  // returned an array
                  if (res.hasOwnProperty('products')) {
                    if (res.products.length > 0) {
                      const prod = res.products[0];
                      if (prod.hasOwnProperty('id')) {
                        this.intItemId = prod.id;
                      }
                      if (prod.hasOwnProperty('name')) {
                        this.intItemName = prod.name;
                      }
                      if (prod.hasOwnProperty('variants')) {
                        this.donAmounts = prod.variants;
                      }
                      if (prod.hasOwnProperty('basePrice')) {
                        this.basePrice = parseFloat(prod.basePrice);
                      }
                      if (prod.hasOwnProperty('committee')) {
                        const theCommittee = prod.committee;
                        this.committeeChairEmail = theCommittee.contactEmail;
                        this.committeeChairName = theCommittee.contactName;
                      }
                      const availabilityMap = this.cartService.productIsAvailable(prod);
                      this.productNotAvailable = availabilityMap.productNotAvailable;
                      this.availabilityDate = availabilityMap.availabilityDate;
                      this.availabilityDateTime = availabilityMap.availabilityDateTime;
                      this.deadlineTime = availabilityMap.deadlineTime;
                      this.deadline = availabilityMap.deadline;
                      this.comingSoon = availabilityMap.comingSoon;
                      this.soldOut = availabilityMap.soldOut;

                    }
                    this.showSpinner = false;
                  }
                });
              },
              () => {
                this.ngZone.run(() => {
                  this.showSpinner = false;
                  setTimeout(() => {
                    this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
                    this.errorModalRef.componentInstance.errorMessage = 'read the list of donation amounts';
                    this.errorModalRef.componentInstance.longErrorMessage = '';
                  }, 500);
                });
              });
        }
      });
    });
    this.cartService.getCartChangingNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.myProductIsChanging) {
            this.showSpinner = res;
            this.myProductIsChanging = res;
          }
        });
      });
  }

  changeDonAmount(event) {
    const theSelectedAmount = parseInt(this.donForm.controls.donAmount.value, 10);
    const theProductSelected = this.donAmounts.filter((donAmount) => donAmount.id === theSelectedAmount);
    if (theProductSelected.length > 0) {
      const aProd = theProductSelected[0];
      this.intItemCost = this.basePrice + parseFloat(aProd.costVariant);
    }
  }

  addToCart() {
    if (this.donForm.invalid) {
      setTimeout(() => {
        console.log('invalid donation amount');
        this.errorModalRef = this.modalService.open(ErrorModalComponent, {size: 'lg'});
        this.errorModalRef.componentInstance.errorMessage = '';
        this.errorModalRef.componentInstance.longErrorMessage = 'Please select a donation amount and try again.';
      }, 500);
      return;
    }
    const dPoints = {};
    const product = {
      productId: this.intItemId,
      productVariantId: parseInt(this.donForm.controls.donAmount.value, 10),
      quantity: 1,
      dataPoints: JSON.stringify(dPoints)
    };
    this.myProductIsChanging = true;
    this.showSpinner = true;
    this.cartService.addItemToCart(product);
    this.googleService.emitEvent('AddToCart', 'Shopping', 'click',
      this.intItemName + ':' + parseInt(this.donForm.controls.donAmount.value, 10), 1);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
