<form id="lookupForm" [formGroup]="lookupForm" [ngStyle]="{ 'display': showTheUI }">
  <p>Look up your dog in the NCA Dog Database or enter the information manually</p>
  <div class="wrapper">
    <div class="overlay" *ngIf="byNameTableShownSpinner">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>

    <div class="loaded-content" [class.blurred]="byNameTableShownSpinner">
      <div *ngIf="!reviewFound" class="table-responsive">
        <div class="form-row mb-4">
          <div class="col-md-8">
            <div class="input-group">
              <input type="text" class="form-control" id="dogNameOrId" name="dogNameOrId" formControlName="dogNameOrId"
                     placeholder="Please enter any part of the dog's name or registered ID:">
              <button type="button" (click)="lookupDog()" class="btn btn-info quick_links_action input-group-append ms-2 me-2" [disabled]="formDisabled">Look up dog</button>
              <button type="button" class="btn btn-secondary btn-lookup" (click)="manual()">Enter Dog Manually</button>
            </div>
          </div>
        </div>
        <h4 class="mx-auto">Please make a selection from these possible matches</h4>
        <table class="table table-entry table-dog-entry selectable table-bordered table-hover mx-auto">
          <tr>
            <th>Dog Name</th>
            <th>Dog Reg ID</th>
            <th>Date of Birth</th>
          </tr>
          <tr *ngFor="let aDog of pagedItems" (click)="selectDog(aDog)">
            <td>{{ getRegisteredName(aDog) }}</td>
            <td>{{ getRegistryId(aDog) }}</td>
            <td>{{ getDateOfBirth(aDog) }}</td>
          </tr>
          <tr *ngIf="noResultsFoundFromSearch">
            <td class="text-center" colspan="6">{{ searchResultsString }}</td>
          </tr>
        </table>
        <!-- pager -->
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
          <li [ngClass]="getClassForFirst()">
            <a (click)="setPage(1)" class="page-link">First</a>
          </li>
          <li [ngClass]="getClassForFirst()">
            <a (click)="setPage(pager.currentPage - 1)" class="page-link">Previous</a>
          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="getClassForPage(page)">
            <a (click)="setPage(page)" class="page-link">{{page}}</a>
          </li>
          <li [ngClass]="getClassForLast()">
            <a (click)="setPage(pager.currentPage + 1)" class="page-link">Next</a>
          </li>
          <li [ngClass]="getClassForLast()">
            <a (click)="setPage(pager.totalPages)" class="page-link">Last</a>
          </li>
        </ul>
      </div>
      <div *ngIf="reviewFound && !manualEntry">
        <div>

          <p>If this is the not the correct dog, you can either go back to search again or enter your dog's information manually</p>
        </div>
        <div class="text-center mb-4">
          <button type="button" class="btn btn-info quick_links_action me-2" (click)="selectThisDog()">Yes, this is My Dog</button>
          <button (click)="backToSearch()" class="btn btn-info quick_links_action_negative me-2" type="button" id="entry-back"><< No, Back to Search Results</button>
          <button type="button" class="btn btn-secondary btn-lookup" (click)="manual()">No, Enter Dog Manually</button>
        </div>
        <div class="table-responsive">
          <table class="table table-entry table-dog-entry table-bordered table-hover mx-auto">
            <tr>
              <td>Dog's Name</td><td>{{ getSelectedRegisteredName() }}</td>
            </tr>
            <tr>
              <td>Dog's Full Titles</td><td><input class="w-100" placeholder="Dog Registered Name with full titles" (change)="titlesChanged()" formControlName="titlesString"></td>
            </tr>
            <tr>
              <td>Registration Number</td><td>{{ getSelectedRegistryId() }}</td>
            </tr>
            <tr>
              <td>Sex</td><td>{{ getSelectedDogGender() }}</td>
            </tr>
            <tr>
              <td>Date of Birth</td><td>{{ getSelectedDateOfBirth() }}</td>
            </tr>
            <tr>
              <td>Call Name</td>
              <td><input class="w-100" placeholder="Dog Call Name" (change)="callNameSet()" formControlName="callName">
                <p class="text-danger" *ngIf="missingCallName">Please enter a call name</p>
              </td>
            </tr>
            <tr>
              <td>Sire</td><td>{{ getSelectedSireRegisteredName() }}</td>
            </tr>
            <tr>
              <td>Sire Full Titles</td><td><input class="w-100" placeholder="Sire Registered Name with full titles" (change)="sireTitlesChanged()" formControlName="sireTitlesString"></td>
            </tr>
            <tr>
              <td>Sire Registry Id</td><td><input class="w-100" placeholder="Sire Registry ID" (change)="sireIdChanged()" formControlName="sireRegId"></td>
            </tr>
            <tr>
              <td>Dam</td><td>{{ getSelectedDamRegisteredName() }}</td>
            </tr>
            <tr>
              <td>Dam Full Titles</td><td><input class="w-100" placeholder="Dam Registered Name with full titles" (change)="damTitlesChanged()" formControlName="damTitlesString"></td>
            </tr>
            <tr>
              <td>Dam Registry Id</td><td><input class="w-100" placeholder="Dam Registry ID" (change)="damIdChanged()" formControlName="damRegId"></td>
            </tr>
            <tr>
              <td>Breeder</td>
              <td><input class="w-100" placeholder="Breeder Name" (change)="breederNameSet()" formControlName="breederName">
                <p class="text-danger" *ngIf="missingBreederName">Please enter the Breeder's name or unknown</p>
              </td>
            </tr>
            <tr>
              <td>Owner</td>
              <td><input class="w-100" placeholder="Owner Name" (change)="ownerNameSet()" formControlName="ownerName">
                <p class="text-danger" *ngIf="missingOwnerName">Please enter the Owner's name or unknown</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div *ngIf="reviewFound && manualEntry">
        <mat-form-field class="inputField-half-width inputField-half-width-left0">
          <input matInput placeholder="Dog Registration ID" formControlName="regIdMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regIdMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regIdMan').hasError(validation.type) && (lookupForm.get('regIdMan').dirty || lookupForm.get('regIdMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-half-width">
          <mat-label>Select an organization</mat-label>
          <mat-select formControlName="regOrgMan" placeholder="Registration Organization">
            <mat-option value="AKC">AKC</mat-option>
            <mat-option value="CKC">CKC</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
          <mat-error *ngFor="let validation of accountValidationMessages.regOrgMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regOrgMan').hasError(validation.type) && (lookupForm.get('regOrgMan').dirty || lookupForm.get('regOrgMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-half-width inputField-half-width-left0">
          <input matInput placeholder="Dog's Registered Name" formControlName="regDogNameMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regDogNameMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regDogNameMan').hasError(validation.type) && (lookupForm.get('regDogNameMan').dirty || lookupForm.get('regDogNameMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-half-width">
          <input matInput placeholder="Dog's Call Name" formControlName="regDogCallNameMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regDogCallNameMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regDogCallNameMan').hasError(validation.type) && (lookupForm.get('regDogCallNameMan').dirty || lookupForm.get('regDogCallNameMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-full-width">
          <input matInput placeholder="Enter Full Dog Registered Name including Titles" formControlName="regTitledNameMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regTitledNameMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regTitledNameMan').hasError(validation.type) && (lookupForm.get('regTitledNameMan').dirty || lookupForm.get('regTitledNameMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-half-width inputField-half-width-left0">
          <input matInput [matDatepicker]="picker" [max]="dobMaxDate" placeholder="Dog's Date of Birth (mm/dd/yyyy)" formControlName="regDogDOBMan">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngFor="let validation of accountValidationMessages.regDogDOBMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regDogDOBMan').hasError(validation.type) && (lookupForm.get('regDogDOBMan').dirty || lookupForm.get('regDogDOBMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-half-width">
          <mat-label>Select a gender</mat-label>
          <mat-select formControlName="regGenderMan" placeholder="Dog Gender">
            <mat-option value="M">Male</mat-option>
            <mat-option value="F">Female</mat-option>
          </mat-select>
          <mat-error *ngFor="let validation of accountValidationMessages.regGenderMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regGenderMan').hasError(validation.type) && (lookupForm.get('regGenderMan').dirty || lookupForm.get('regGenderMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-half-width inputField-half-width-left0">
          <input matInput placeholder="Sire's Name including Titles" formControlName="regSireNameMan">
        </mat-form-field>
        <mat-form-field class="inputField-half-width">
          <input matInput placeholder="Sire's Registration ID" formControlName="regSireRegIdMan">
        </mat-form-field>
        <mat-form-field class="inputField-half-width inputField-half-width-left0">
          <input matInput placeholder="Dam's Name including Titles" formControlName="regDamNameMan">
        </mat-form-field>
        <mat-form-field class="inputField-half-width">
          <input matInput placeholder="Dam's Registration ID" formControlName="regDamRegIdMan">
        </mat-form-field>
        <mat-form-field class="inputField-full-width">
          <input matInput placeholder="Breeder's Name" formControlName="regBreederNameMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regBreederNameMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regBreederNameMan').hasError(validation.type) && (lookupForm.get('regBreederNameMan').dirty || lookupForm.get('regBreederNameMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-full-width">
          <input matInput placeholder="Owner's Name" formControlName="regOwnerNameMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regOwnerNameMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regOwnerNameMan').hasError(validation.type) && (lookupForm.get('regOwnerNameMan').dirty || lookupForm.get('regOwnerNameMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-full-width">
          <input matInput placeholder="Owner's Address" formControlName="regOwnerAddressStreetMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regOwnerAddressStreetMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regOwnerAddressStreetMan').hasError(validation.type) && (lookupForm.get('regOwnerAddressStreetMan').dirty || lookupForm.get('regOwnerAddressStreetMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-third-width">
          <input matInput placeholder="City" formControlName="regOwnerCityMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regOwnerCityMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regOwnerCityMan').hasError(validation.type) && (lookupForm.get('regOwnerCityMan').dirty || lookupForm.get('regOwnerCityMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-third-width">
          <input matInput placeholder="State" formControlName="regOwnerStateMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regOwnerStateMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regOwnerStateMan').hasError(validation.type) && (lookupForm.get('regOwnerStateMan').dirty || lookupForm.get('regOwnerStateMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="inputField-third-width">
          <input matInput placeholder="Zipcode" formControlName="regOwnerZipMan">
          <mat-error *ngFor="let validation of accountValidationMessages.regOwnerZipMan">
            <mat-error class="error-message" *ngIf="lookupForm.get('regOwnerZipMan').hasError(validation.type) && (lookupForm.get('regOwnerZipMan').dirty || lookupForm.get('regOwnerZipMan').touched)">{{validation.message}}</mat-error>
          </mat-error>
        </mat-form-field>
        <div class="text-center mb-4 mt-4">
          <button type="button" class="btn btn-info quick_links_action me-2" (click)="selectThisDogMan()">Yes, this is My Dog</button>
          <button (click)="backToSearch()" class="btn btn-info quick_links_action_negative me-2" type="button"><< No, Back to Search Results</button>
        </div>
      </div>
    </div>
  </div>
</form>
