<nav class="navbar navbar-expand-lg navbar-dark border-bottom align-items-start"  style="background-color: #900909;">
  <div class="container-fluid">
    <a class="navbar-brand text-white" routerLink="/shop/main" routerLinkActive="active">
      Newfoundland Club Of America
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            (click)="isMenuCollapsed = !isMenuCollapsed"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav ms-4">
        <li ngbDropdown class="nav-item" role="presentation">
          <a class="nav-link text-white bg-transparent" ngbDropdownToggle>Info</a>
          <div ngbDropdownMenu>
            <a ngbDropdownItem [routerLink]="'shop/directory'" (click)="isMenuCollapsed = true">Directory</a>
            <a ngbDropdownItem [routerLink]="'shop/hotel'">Hotel Info</a>
            <a ngbDropdownItem [routerLink]="'shop/showsuper'" (click)="isMenuCollapsed = true">Show Superintendent</a>
            <a ngbDropdownItem [routerLink]="'shop/opening'" [disabled]="true" (click)="isMenuCollapsed = true">Opening Ceremonies</a>
            <a ngbDropdownItem [routerLink]="'shop/juniors'" (click)="isMenuCollapsed = true">Juniors Information</a>
            <a ngbDropdownItem [routerLink]="'shop/wdcteam'" (click)="isMenuCollapsed = true">Working Dog Team Relay</a>
          </div>
        </li>
        <li ngbDropdown class="nav-item" role="presentation">
          <a class="nav-link text-white bg-transparent" ngbDropdownToggle>Merchandise</a>
          <div ngbDropdownMenu>
            <a ngbDropdownItem [routerLink]="'shop/logo'" (click)="isMenuCollapsed = true">Logo Merchandise</a>
            <a ngbDropdownItem [routerLink]="'shop/top20'" (click)="isMenuCollapsed = true">Top 20 Merchandise</a>
            <a ngbDropdownItem [routerLink]="'shop/postshowmerch'" [disabled]="true" (click)="isMenuCollapsed = true">Post Show Merchandise</a>
          </div>
        </li>
        <li ngbDropdown class="nav-item" role="presentation">
          <a class="nav-link text-white bg-transparent" ngbDropdownToggle>Reservations</a>
          <div ngbDropdownMenu>
            <a ngbDropdownItem [routerLink]="'shop/bulkwater'" (click)="isMenuCollapsed = true">Bulk Water</a>
            <a ngbDropdownItem [routerLink]="'shop/catalogAds'" (click)="isMenuCollapsed = true">Catalog Ads</a>
            <a ngbDropdownItem [routerLink]="'shop/cgc'" (click)="isMenuCollapsed = true">CGC &amp; Trick Dog</a>
            <a ngbDropdownItem [routerLink]="'shop/draft'" (click)="isMenuCollapsed = true">Draft Test</a>
            <a ngbDropdownItem [routerLink]="'shop/golfcart'" (click)="isMenuCollapsed = true">Golf Carts</a>
            <a ngbDropdownItem [routerLink]="'shop/grooming'" (click)="isMenuCollapsed = true">Grooming &amp; Bathing</a>
            <a ngbDropdownItem [routerLink]="'shop/health'" (click)="isMenuCollapsed = true">Health Clinics</a>
            <a ngbDropdownItem [routerLink]="'shop/honors'" (click)="isMenuCollapsed = true">Honors Parade</a>
            <a ngbDropdownItem [routerLink]="'shop/international'" (click)="isMenuCollapsed = true">International</a>
            <a ngbDropdownItem [routerLink]="'shop/livlegs'" (click)="isMenuCollapsed = true">Living Legends</a>
            <a ngbDropdownItem [routerLink]="'shop/meals'" (click)="isMenuCollapsed = true">Meals &amp; Banquet</a>
            <a ngbDropdownItem [routerLink]="'shop/parking'" (click)="isMenuCollapsed = true">Parking</a>
            <a ngbDropdownItem [routerLink]="'shop/postshow'" (click)="isMenuCollapsed = true">Post Show Catalogs</a>
            <a ngbDropdownItem [routerLink]="'shop/rvparking'" (click)="isMenuCollapsed = true">RV Parking</a>
            <a ngbDropdownItem [routerLink]="'shop/regionaltrophies'" (click)="isMenuCollapsed = true">Regional Trophies</a>
            <a ngbDropdownItem [routerLink]="'shop/register'" (click)="isMenuCollapsed = true">Registration</a>
            <a ngbDropdownItem [routerLink]="'shop/seating'" (click)="isMenuCollapsed = true">Ringside Seating</a>
            <a ngbDropdownItem [routerLink]="'shop/carting'" (click)="isMenuCollapsed = true">Specialty Carting</a>
            <a ngbDropdownItem [routerLink]="'shop/trophies'" (click)="isMenuCollapsed = true">Uniform Trophies</a>
            <a ngbDropdownItem [routerLink]="'shop/wdcteam'" (click)="isMenuCollapsed = true">Working Dog Team Relay</a>
            <a ngbDropdownItem [routerLink]="'shop/vip'" (click)="isMenuCollapsed = true">VIP Packages</a>
          </div>
        </li>
        <li ngbDropdown class="nav-item" role="presentation">
          <a class="nav-link text-white bg-transparent" ngbDropdownToggle>Downloads</a>
          <div ngbDropdownMenu>
            <a ngbDropdownItem href="{{ packetLink }}" download="" [disabled]="true" (click)="isMenuCollapsed = true">Download Packet</a>
            <a ngbDropdownItem href="{{ formLink }}" download="" [disabled]="true" (click)="isMenuCollapsed = true">Download Forms</a>
          </div>
        </li>
        <li ngbDropdown class="nav-item" role="presentation">
          <a class="nav-link text-white bg-transparent" ngbDropdownToggle>Forms</a>
          <div ngbDropdownMenu>
            <a ngbDropdownItem [routerLink]="'shop/auctions'">Auctions</a>
            <a ngbDropdownItem [routerLink]="'shop/lovett'" (click)="isMenuCollapsed = true">Lovett Challenge</a>
            <a ngbDropdownItem [routerLink]="'shop/meeting'" (click)="isMenuCollapsed = true">Meeting Rooms</a>
            <a ngbDropdownItem [routerLink]="'shop/rescue'" (click)="isMenuCollapsed = true">Rescue Parade</a>
            <a ngbDropdownItem [routerLink]="'shop/seminars'" (click)="isMenuCollapsed = true">Seminars</a>
            <a ngbDropdownItem [routerLink]="'shop/vendors'" (click)="isMenuCollapsed = true">Vendors</a>
            <a ngbDropdownItem [routerLink]="'shop/volunteers'" (click)="isMenuCollapsed = true">Volunteers</a>
            <a ngbDropdownItem [routerLink]="'shop/wdcforum'" (click)="isMenuCollapsed = true">Working Dog Forum</a>
          </div>
        </li>
        <li class="d-none nav-item d-flex">
          <button type="button" (click)="registerNow()" class="nav-link nav-link-header">Register Now!</button>
        </li>

      </ul>
    </div>
    <div class="ms-auto d-inline-flex align-items-center">
      <li *ngIf="!isLoggedIn" class="nav-item d-flex">
        <button type="button" (click)="login()" class="nav-link nav-link-header">Login</button>
      </li>
      <li *ngIf="isLoggedIn" class="nav-item d-flex">
        <button type="button" (click)="logout()" class="nav-link nav-link-header">Logout</button>
      </li>
      <li class="nav-item d-flex">
        <button type="button" (click)="showCart()" class="nav-link nav-link-header">Cart: {{ this.totalOrderCost | currency }}</button>
      </li>
    </div>
  </div>
</nav>

