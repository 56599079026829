<div class="container-fluid">
  <div class="row shop_page_content">
    <div class="col-md-6 me-auto">
      <p class="quick_links">Quick Links:
        <a class="btn btn-info quick_links_action ms-1" href="assets/2025/2025RescueForm.pdf" download="">Download Form</a>
        <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">Email
          Committee Chair</a>
      </p>
    </div>
  </div>
  <div class="row shop_page_content">
    <div class="col-md-10 col-sm-10 col-xs-12">
      <h3>RESCUE CEREMONY</h3>
      <p>
      Tell everyone at the National Specialty the story of how your great rescue dog came into your
life. Join us for the Rescue Parade on Friday, May 2nd where your special story will be shared with the audience as
you and your dog walk across the ring to receive your commemorative medallion. </p>
      <p><em>Deadline: {{ deadline }}</em></p>
      <p>Questions? <a class="btn btn-info quick_links_action ms-1" href="mailto: {{ committeeChairEmail }}" download="">
        Please email the Committee Chair</a>
      </p>
      <form id="rescueForm" [formGroup]="rescueForm" #lf="ngForm">
        <div class="form-row">
          <mat-form-field class="inputField-half-width-left0">
            <input matInput placeholder="Full Name" formControlName="name">
            <mat-error *ngFor="let validation of accountValidationMessages.name">
              <mat-error class="error-message"
                         *ngIf="rescueForm.get('name').hasError(validation.type) && (rescueForm.get('name').dirty || rescueForm.get('name').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="inputField-half-width">
            <mat-label>Are you an NCA Member?</mat-label>
            <mat-select formControlName="isMember" placeholder="NCA Member?">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="inputField-full-width">
            <input matInput placeholder="Full Address" formControlName="address">
            <mat-error *ngFor="let validation of accountValidationMessages.address">
              <mat-error class="error-message"
                         *ngIf="rescueForm.get('address').hasError(validation.type) && (rescueForm.get('address').dirty || rescueForm.get('address').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="inputField-third-width">
            <input matInput placeholder="Email Address" formControlName="emailAddress">
            <mat-error *ngFor="let validation of accountValidationMessages.emailAddress">
              <mat-error class="error-message"
                         *ngIf="rescueForm.get('emailAddress').hasError(validation.type) && (rescueForm.get('emailAddress').dirty || rescueForm.get('emailAddress').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="inputField-third-width">
            <input matInput placeholder="Daytime Phone" formControlName="dayphone">
          </mat-form-field>
          <mat-form-field class="inputField-third-width">
            <input matInput placeholder="Evening Phone" formControlName="eveningphone">
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="inputField-full-width">
            <input matInput placeholder="Dog Name" formControlName="dogName">
            <mat-error *ngFor="let validation of accountValidationMessages.dogName">
              <mat-error class="error-message"
                         *ngIf="rescueForm.get('dogName').hasError(validation.type) && (rescueForm.get('dogName').dirty || rescueForm.get('dogName').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="inputField-half-width-left0">
            <input matInput placeholder="Dog Sex" formControlName="dogSex">
            <mat-error *ngFor="let validation of accountValidationMessages.dogSex">
              <mat-error class="error-message"
                         *ngIf="rescueForm.get('dogSex').hasError(validation.type) && (rescueForm.get('dogSex').dirty || rescueForm.get('dogSex').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="inputField-half-width">
            <input matInput placeholder="Dog Date of Birth" formControlName="dogDOB">
            <mat-error *ngFor="let validation of accountValidationMessages.dogDOB">
              <mat-error class="error-message"
                         *ngIf="rescueForm.get('dogDOB').hasError(validation.type) && (rescueForm.get('dogDOB').dirty || rescueForm.get('dogDOB').touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-row mb-3">
          <mat-form-field class="inputField-full-width">
            <textarea matInput  formControlName="story"
                   placeholder="In 75 words or less, describe how your dog came to be a member of the family.
                    (Over 75 words will be edited.)"></textarea>
          </mat-form-field>
        </div>
        <button type="button" (click)="sendForm()" class="btn btn-info quick_links_action">Send Form</button>
      </form>

    </div>
  </div>
</div>

